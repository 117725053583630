import { createStore } from 'vuex'
import { getinvestorinfo, getsiteconfig } from "../common/api";


export default createStore({
  state: {
    investorinfo: {},
    siteconfig: {},
    rewardfund: ""
  },
  getters: {

  },
  mutations: {
    updateinvestorinfo(state, val) {
      state.investorinfo = val;
      // Vue.set(state.investorinfo, "balance", val.balance);
      // Vue.set(state.investorinfo, "valueadded_treasure_balance", val.valueadded_treasure_balance);
    },
    updatebalance(state, val) {
      state.balance = val;
    },
    updatesiteconfig(state, val) {
      state.siteconfig = val;
    },
    updaterewardfund(state, val) {
      state.rewardfund = val;
    }
  },
  actions: {
    asyncupdateinvestorinfo(store) {
      getinvestorinfo({}).then((res)=>{
        if(res.code === 200) {
          store.commit('updateinvestorinfo', res.data);
          store.commit('updatebalance', res.data.balance);
        } else if (res.code === 403) {
          localStorage.removeItem("imfpagetoken");
        }
      });
    },
    asyncupdatesiteconfig(store) {
      getsiteconfig({}).then((res)=>{
        if(res.code === 200) {
          store.commit('updatesiteconfig', res.data);
        }
      })
    }
  },
  modules: {
    
  }
})
