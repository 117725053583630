const InfiniteScroll = {
  mounted(el, binding) {
    function scrollhander(e){
      const busy = el.getAttribute('data-infiniteScrollDisabled');
      const distance = el.getAttribute('data-infiniteScrollDistance')
        ? Number.parseInt(el.getAttribute('data-infiniteScrollDistance'), 10)
        : 100;
      console.log("loading: ", busy);
      if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < distance) {
        el.setAttribute('data-infiniteScrollDisabled', true);
        binding.value(e);
        console.log("loadmore");
      }
    }
    el.__vueScrollOutside__ = scrollhander;
    el.addEventListener('scroll', scrollhander);
  },
  beforeUnmount (el, binding) {
    document.removeEventListener("scroll", el.__vueScrollOutside__);
    delete el.__vueScrollOutside__;
  }
};

export default InfiniteScroll;