import axios from "axios";
import router from "../router";


function get_apihostbak() {
    $.get("https://apihost.guozehui.workers.dev/", function(data){
        localStorage.setItem("imfapihost",data.host);
    });
}

if(process.env.NODE_ENV === "moni") {
    axios.defaults.baseURL = 'http://mutualfund.win:12368';  //mo-ni
} else {
    axios.defaults.baseURL = 'https://apimpacq.com';
    $.ajax({
        type: "get",
        url: "https://apihost.iphostapi.workers.dev/",
        async: true,
        success: function (data) {
            localStorage.setItem("imfapihost",data.host);
        },
        // Ajax接口出现错误时执行下述代码
        error: function(XMLHttpRequest, textStatus, errorThrown) {
            get_apihostbak();
        }
    });
}

axios.defaults.headers["Content-Type"] = "multipart/form-data";

//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    config => {
        // 在发送请求之前做什么
        config.headers["FUNDTOKEN"] = localStorage.getItem("imfpagetoken") || "";
        config.headers["Accept-Language"] = localStorage.getItem("language") || "en-us";
        config.baseURL = localStorage.getItem("imfapihost") || axios.defaults.baseURL;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if(response.data.code === 403) {
                toastbox("error", 2000, "Login expired");
                localStorage.removeItem("imfpagetoken");
                router.push({"name": "login"});
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        loading_close();
        toastbox(error.code, "BaseUrl:" + error.config.baseURL + " errorcode:" + error.response.data.code);
    }
);
export default {
    /**
     * @param {String} url 
     * @param {Object} data 
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'post',
                    url,
                    data: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'get',
                    url,
                    params: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    patch(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'patch',
                    url,
                    data: data,
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
};
