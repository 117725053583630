<template>
  <!-- App Header -->
  <div class="appHeader">
    <div class="left" v-if="showleft">
      <a href="javascript:;" class="headerButton" v-if="title !== 'IM FUND'" @click="goback">
          <ion-icon name="chevron-back-outline"></ion-icon>
      </a>
    </div>
    <div class="pageTitle">{{title}}</div>
    <div class="right">
      <a href="javascript:;" class="headerButton" @click="handlelangflagmenu">
          <img class="langflag" :src="langsrc" />
      </a>
      <div class="dropdown-menu" id="flagmenu">
          <a class="dropdown-item" href="javascript:;" @click="handlechoselange('en-us')">
              <img class="langflagicon" src="/assets/img/lang/en-us.webp"/>
              English
          </a>
          <a class="dropdown-item" href="javascript:;" @click="handlechoselange('hi')">
              <img class="langflagicon" src="/assets/img/lang/hi.webp"/>
              हिन्दी
          </a>
          <!-- <a class="dropdown-item" href="javascript:;" @click="handlechoselange('ru')">
              <img class="langflagicon" src="assets/img/lang/ru.webp"/>
              Русский
          </a> -->
      </div>
    </div>
  </div>
  <!-- * App Header -->
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: ""
    },
    showleft: {
      type: Boolean,
      default: true
    },
    goroutername: {
      type: String,
      default: "back"
    },
  },
  data() {
    return {
      dropdownisshow: false,
      langsrc: "/assets/img/lang/en-us.webp",
      refulsh_router_name: ["home", "newsdetail", "team", "funddetail", "news", "my", "fund", "MyFundsDetails", "myfunddetail"]
    }
  },
  created() {
    let lang = localStorage.getItem('language') || 'en-us';
    this.$i18n.locale = lang;
    localStorage.setItem('language', this.$i18n.locale);
    this.langsrc = "/assets/img/lang/" + lang + '.webp';
  },
  methods: {
    goback() {
      if(this.goroutername === "back") {
        this.$router.back();
      } else {
        this.$router.push({"name": this.goroutername});
      }
    },
    handlelangflagmenu() {
      if(!this.dropdownisshow){
        $('#flagmenu').css("display", "block");
        this.dropdownisshow=true;
      } else {
        $('#flagmenu').css("display", "none");
        this.dropdownisshow=false;
      }
    },
    handlechoselange(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('language', this.$i18n.locale);
      this.langsrc = "/assets/img/lang/" + lang + '.webp';
      $('#flagmenu').css("display", "none");
      this.dropdownisshow=false;
      if(this.refulsh_router_name.indexOf(this.$router.currentRoute.value.name) !== -1) {
          this.$router.go(0);
      }
    }
  }
}
</script>

<style scoped>
.langflag {
  width: 46px;
  height: 28px;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: -91px;
    right: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1pxsolidrgba(0,0,0,.15);
    border-radius: 0.25rem;
}
.langflagicon {
  width: 28px;
  height: 16px;
}
</style>
