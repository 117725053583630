<template>
    <Header :title="$t('My Funds')"></Header>
    <div id="appCapsule">
        <ul class="listview image-listview media mb-2 wrapper">
            <li v-if="my_logs.length === 0">
                <div class="in">
                    {{ $t("No data") }}
                </div>
            </li>
            <li v-for="item in my_logs" v-bind:key="item.id">
                <a href="javascript:;" class="item">
                    <div class="imageWrapper">
                        <img :src="$store.state.siteconfig.images_host + item.images" onerror="this.onerror='';src='assets/img/fund.webp'" alt="image" class="imaged w64">
                    </div>
                    <div class="in">
                        <div>
                            <!--  <div @click="$router.push('/funddetail/' + item.id)"></div> -->
                            <div style="text-align: left;">{{ item.name }}</div>
                            <div class="text-muted-cycle">{{ $t('Principal Amount') }}: {{ item.total_principal_amount }}</div>
                            <div class="text-muted-cycle">{{ $t('Daily income') }}: {{ item.interest_rate }}%</div>
                            <div class="text-muted-cycle">{{ $t("Income") }}: {{ item.total_investment_interest }}</div>
                            <div class="text-muted-cycle">{{$t("Quantity")}}: {{ item.total_buy_times }}</div>
                            <div class="text-muted-cycle">{{$t("Quota")}}: {{$t("$")}}{{item.quota}}</div>
                        </div>
                        <div>
                            <!-- <p>₹{{ item.initial_amount }}</p> -->
                            <button type="button" class="btn btn-primary btn-sm mr-1 btn-right" @click="$router.push('/myfunddetail/' + item.id)">{{ $t("Details") }}</button>
                        </div>
                    </div>
                </a>
                <div v-if="JSON.stringify(item.buyinfo) !== '{}'">
                    <p class="buyinfo">
                        <span>{{ $t("Start time") }}: {{ item.last_buy_time }}</span>
                        <span>{{ $t("Expire date") }}: {{ item.expire_date }}</span>
                    </p>
                </div>
            </li>
            <li>
                <div class="spinner-border text-info mt-2" role="status" v-if="loading_show"></div>
            </li>
            <BuyFund :curr_fund="curr_fund" @refulshfundlist="refulshfundlist"></BuyFund>
        </ul>
    </div>
    <BottomMenu></BottomMenu>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BottomMenu from "../components/BottomMenu.vue";
import { getmyitem } from "../common/api";

export default {
    name: "TransactionDetails",
    components: {
        Header,
        BottomMenu,
    },
    data() {
        return {
            my_logs: [],
        };
    },
    mounted() {
        this.getmyitem();
    },
    methods: {
        getmyitem() {
            getmyitem({}).then((res) => {
                if (res.code === 200) {
                    this.my_logs.push(...res.data);
                }
            });
        }
    },
};
</script>

<style scoped>
.btn-right {
    margin-right: -15px !important;
}

.listview-title {
    color: #1E74FD;
    padding: 1px 14px;
}

.listview .text-muted {
    font-size: 13px;
    color: red !important;
    text-align: left;
}

.image-listview>li a.item:after {
    background: none;
    content: none;
}

.listview .text-muted-cycle {
    font-size: 13px;
    color: #A1A1A2 !important;
    text-align: left;
}

.fund-progress {
    margin: 2px 3px;
}

.imaged.w64 {
    width: 64px !important;
    height: 64px !important;
    margin-right: 10px;
}

.wrapper {
    height: calc(100vh - 55px);
    overflow: scroll;
}

.buyinfo {
    font-size: .85em;
    margin-bottom: 0rem;
    color: #586d7f !important;
}

.buyinfo span {
    margin-right: 15px;
}
</style>