<template>
    <!-- toast center tap to close -->
  <div
    id="error"
    class="toast-box toast-center tap-to-close"
    style="z-index: 1060"
  >
    <div class="in">
      <div class="text" id="errormsg">
      </div>
    </div>
  </div>
  <!-- toast center tap to close -->

  <!-- toast center iconed -->
  <div id="success" class="toast-box toast-center" style="z-index: 1060">
    <div class="in">
      <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
      <div class="text" id="successmsg">
      </div>
    </div>
    <!-- <button type="button" class="btn btn-sm btn-text-light close-button">
      {{ $t("OK") }}
    </button> -->
  </div>
  <!-- toast center iconed -->
</template>
<script>

export default {
  name: 'SuccessErr',
}
</script>
