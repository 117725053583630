const InfiniteScrollDisabled = {
  beforeMount(el, binding) {
    el.setAttribute('data-infiniteScrollDisabled', binding.value);
  },
  updated(el, binding) {
    el.setAttribute('data-infiniteScrollDisabled', binding.value);
  }
};

export default InfiniteScrollDisabled;
