<template>
  <Header :title="$t('Fund detail')"></Header>
  <!-- App Capsule -->
  <div id="appCapsule">

    <div class="listview-title mt-1">{{$t("Fund info")}}</div>
    <ul class="listview image-listview media mb-2">
      <li>
          <a href="javascript:;" class="item">
              <div class="imageWrapper" @click="$router.push('/funddetail/' + item.id)">
                  <img :src="$store.state.siteconfig.images_host + item.images" onerror="this.onerror='';src='assets/img/fund.webp'" alt="image" class="imaged w64">
              </div>
              <div class="in">
                  <div @click="$router.push('/funddetail/' + item.id)">
                      <div style="text-align: left;">{{item.name}}</div>
                      <div class="text-muted">{{$t('Daily income')}}: {{item.interest_rate}}%</div>
                      <div class="text-muted-cycle">{{$t("Cycle")}}: {{item.cycle}} days</div>
                      <div class="text-muted">{{$t("Quantity")}}: {{item.every_buy_max_times}}</div>
                      <div class="text-muted-cycle">{{$t("Quota")}}: {{$t("$")}}{{item.quota}}</div>
                  </div>
                  <!-- <div>
                      <p>₹{{item.initial_amount}}</p>
                      <button type="button" class="btn btn-primary btn-sm mr-1" @click="showmodal(item)">{{$t("Buy")}}</button>
                  </div> -->
              </div>
          </a>
          <div>
          <p class="buyinfo">
            <span>{{$t("Start time")}}: {{item.last_buy_time}}</span>
            <span>{{$t("Expire date")}}: {{item.expire_date}}</span>
          </p>
          <p class="buyinfo">
            <span>{{$t("Principal Amount")}}: {{$t('$')}}{{item.total_principal_amount}}</span>
            <span>{{$t("Income")}}: {{$t('$')}}{{item.total_investment_interest}}</span>
            <span>{{$t("Quantity")}}: {{item.total_buy_times}}</span>
          </p>
          </div>
          <div class="progress fund-progress ml-1 mr-1">
              <div class="progress-bar" role="progressbar" :style="'width: ' + String(item.progress) + '%;'" :aria-valuenow="item.progress"
                  aria-valuemin="0" aria-valuemax="100"><span v-if="item.is_showprogress">{{item.progress}}%</span></div>
          </div>
      </li>
    </ul>


    <div class="section full mt-1 mb-2">
      <div class="listview-title mt-1">{{ $t("Interest Details") }}</div>
      <div class="wide-block p-0">
          <div class="table-responsive">
              <table class="table">
                  <thead>
                      <tr>
                          <th scope="col">{{$t("amount")}}</th>
                          <th scope="col">{{$t("Date")}}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-if="interestdetails.length === 0">  
                          <td colspan="2" >{{ $t("No data") }}</td>
                      </tr>
                      <tr v-for=" itd in interestdetails" v-bind:key="itd.id">
                          <td>{{$t("$")}}{{itd.amount}}</td>
                          <td>{{itd.ctime}}</td>
                      </tr>
                  </tbody>
              </table>
              <nav v-if="interestdetails.length !== 0">
                  <ul class="pagination pagination-secondary">
                      <li class="page-item"><a class="page-link" @click="interestdetailbtn(0)">{{ $t("Previous") }}</a></li>
                      <li class="page-item"><a class="page-link">{{ipage}} / {{ipagecount}}</a></li>
                      <li class="page-item"><a class="page-link" @click="interestdetailbtn(1)">{{ $t("Next") }}</a></li>
                  </ul>
              </nav>
          </div>
      </div>
    </div>

    <div class="section full mt-1 mb-2">
        <div class="listview-title mt-1">{{ $t("Purchase History") }}</div>
      <div class="wide-block p-0">
          <div class="table-responsive">
              <table class="table">
                  <thead>
                      <tr>
                          <th scope="col">{{$t("Date")}}</th>
                          <th scope="col">{{$t("amount")}}</th>
                          <th scope="col">{{$t("Quantity")}}</th>
                          <th scope="col">{{$t("Expire date")}}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-if="buyinfos.length === 0">  
                          <td colspan="4" >{{ $t("No data") }}</td>
                      </tr>
                      <tr v-for="bf in buyinfos" v-bind:key="bf.id">
                          <td>{{bf.ctime}}</td>
                          <td>{{bf.amount}}</td>
                          <td>{{bf.buy_times}}</td>
                          <td>{{bf.expire_date}}</td>
                      </tr>
                  </tbody>
              </table>
              <nav v-if="buyinfos.length !== 0">
                  <ul class="pagination pagination-secondary">
                      <li class="page-item"><a class="page-link" @click="interestdetailbtn(0)">{{ $t("Previous") }}</a></li>
                      <li class="page-item"><a class="page-link">{{buypage}} / {{buypagecount}}</a></li>
                      <li class="page-item"><a class="page-link" @click="interestdetailbtn(1)">{{ $t("Next") }}</a></li>
                  </ul>
              </nav>
          </div>
      </div>
    </div>




    <!-- <BuyFund :curr_fund="item"></BuyFund> -->

  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BuyFund from "../components/BuyFund.vue";
import { getProjectdetail, getiteminterestlog, getitembuyinfo } from "../common/api";
import i18n from "../lang/index";
export default {
  name: "MyfundDetail",
  components: {
    Header,
    BuyFund
  },
  data() {
    return {
      item: {},
      interestdetails: [],
      buyinfos: [],
      buypage: 1,
      buypagecount: 0,
      ipage: 1,
      ipagecount: 0,
      limit: 5
    }
  },
  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
    this.itemid = this.$router.currentRoute.value.params.itemid;
  },
  mounted() {
    getProjectdetail(this.itemid).then((res)=>{
        if(res.code === 200) {
            this.item = res.data;
        }
    });
    this.get_interestdetails();
    this.get_itembuyinfs();
  },
  methods: {
    get_interestdetails() {
      loading(i18n.global.t("In process"));
      getiteminterestlog(this.itemid,{page: this.ipage, limit: this.limit}).then((res) => {
        this.interestdetails = res.data.result;
        this.ipage = res.data.page;
        if (res.data.count % this.limit == 0) {
          this.ipagecount = parseInt(res.data.count / this.limit);
        } else {
          this.ipagecount = parseInt(res.data.count / this.limit) + 1;
        }
        loading_close();
      })
    },
    interestdetailbtn(method) {
      if (method == 0) {
        this.ipage--;
        if (this.ipage < 1) { this.ipage = 1; return; }
      } else {
        this.ipage++;
        if (this.ipage > this.ipagecount) { this.ipage = this.ipagecount; return; }
      }
      this.get_interestdetails();
    },
    buyinfosbtn(method) {
      if (method == 0) {
        this.buypage--;
        if (this.buypage < 1) { this.buypage = 1; return; }
      } else {
        this.buypage++;
        if (this.buypage > this.buypagecount) { this.buypage = this.buypagecount; return; }
      }
      this.get_itembuyinfs();
    },
    get_itembuyinfs() {
      loading(i18n.global.t("In process"));
      getitembuyinfo(this.itemid,{page: this.buypage, limit: this.limit}).then((res) => {
        this.buyinfos = res.data.result;
        this.buypage = res.data.page;
        if (res.data.count % this.limit == 0) {
          this.buypagecount = parseInt(res.data.count / this.limit);
        } else {
          this.buypagecount = parseInt(res.data.count / this.limit) + 1;
        }
        loading_close();
      })
      
    },
    showmodal(){
      if (this.$store.state.investorinfo.balance !== undefined) {
        $("#investormodal").modal();
      } else {
        toastbox("error", 2000, i18n.global.t("Please log in to invest"));
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      }
    }
  }
};
</script>

<style scoped>
.blog-post .post-header {
    font-size: 11px;
    padding: 10px 0;
    margin: 0px 16px;
    color: #A1A1A2;
    display: inherit;
    align-items: center;
    justify-content: space-between;
    border-top: 1px none;
    border-bottom: none;
    text-align: right;
}
.buy-b {
    position: fixed;
    bottom: 0px;
    left: 0px;
}
.listview-title {
    color: #1E74FD;
    padding: 1px 14px;
}
.listview .text-muted {
    font-size: 13px;
    color: red !important;
    text-align: left;
}
.image-listview > li a.item:after {
  background: none;
  content: none;
}
.listview .text-muted-cycle {
    font-size: 13px;
    color: #A1A1A2 !important;
    text-align: left;
}
.fund-progress {
  margin: 2px 3px;
}
.imaged.w64 {
    width: 64px !important;
    height: 64px !important;
    margin-right: 10px;
}
.wrapper {
  height: calc(100vh - 55px);
  overflow: scroll;
}
.buyinfo {
  font-size: .85em;
  margin-bottom: 0rem;
  color: #586d7f !important;
}
.buyinfo span {
  margin-right: 15px;
}
</style>