<template>
  <!-- App Bottom Menu -->
  <div class="appBottomMenu">
    <router-link to="/home" class="item" :class="{ active: ishomeActive }">
      <div class="col">
        <ion-icon name="home-outline"></ion-icon>
      </div>
    </router-link>
    <router-link to="/news" class="item" :class="{ active: isnewsActive }">
      <div class="col">
        <ion-icon name="newspaper-outline"></ion-icon>
      </div>
    </router-link>

    <router-link to="/fund" class="item" :class="{ active: isfundActive }">
      <div class="col">
        <ion-icon name="podium-outline"></ion-icon>
      </div>
    </router-link>

    <router-link to="/team" class="item" :class="{ active: isteamActive }">
      <div class="col">
        <ion-icon name="git-merge-outline"></ion-icon>
      </div>
    </router-link>
    <router-link to="/my" class="item" :class="{ active: isMyActive }">
      <div class="col">
        <ion-icon name="person-circle-outline"></ion-icon>
      </div>
    </router-link>
  </div>
  <!-- * App Bottom Menu -->
</template>

<script>
export default {
  name: "BottomMenu",
  data() {
    return {
      ishomeActive: false,
      isnewsActive: false,
      isfundActive: false,
      isteamActive: false,
      isMyActive: false,
    };
  },
  created() {
    let path = this.$router.currentRoute._rawValue.path;
    switch (path) {
      case "/":
        this.ishomeActive = true;
        break;
      case "/home":
        this.ishomeActive = true;
        break;
      case "/news":
        this.isnewsActive = true;
        break;
      case "/fund":
        this.isfundActive = true;
        break;
      case "/team":
        this.isteamActive = true;
        break;
      case "/my":
        this.isMyActive = true;
        break;
      default:
        break;
    }
    console.log();
  },
};
</script>
