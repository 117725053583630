<template>
  <Header title="Login"></Header>
  <!-- App Capsule -->
  <div id="appCapsule" class="mt-2">
    <div class="login-form mt-1">
      <div class="section mt-1">
        <h1>{{ $store.state.siteconfig.website_name }}</h1>
      </div>
      <div class="section mt-1 mb-5">
        <form>
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="username"
                v-model="phone"
                :placeholder="$t('Phone')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                name="password"
                v-model="password"
                :placeholder="$t('Password')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-links mt-2">
            <div>
              <!-- <router-link to="/register">{{ $t("Register Now") }}</router-link> -->
              <a href="javascript:;"></a>
            </div>
            <div>
              <router-link to="/forgotpassword" class="text-muted">{{ $t('Forgot Password?')}}</router-link>
            </div>
          </div>

          <div class="form-button-group">
            <button
              type="button"
              class="btn btn-primary btn-block btn-lg"
              @click="handleLogin"
              :disabled="login_disabled"
            >
              {{$t("Log in")}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- * App Capsule -->
  <SuccessErr></SuccessErr>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import i18n from '../lang/index';
import SuccessErr from "../components/SuccessErr.vue";
import { login } from "../common/api";
import { phones } from "../common/rex";

export default {
  name: "LoginView",
  components: {
      Header,
      SuccessErr,
  },
  data() {
    return {
      successmsg: "",
      errmsg: "",
      phone: "",
      phone_ups: "",
      password: "",
      download_app_url_show: false,
      login_disabled: false,
      ip: "127.0.0.1",
    };
  },
  created() {
    this.getip();
    this.$store.dispatch('asyncupdatesiteconfig').then(()=>{
      if(process.env.NODE_ENV === "app") {
        this.download_app_url_show = false;
      } else {
        this.download_app_url_show = true;
      }
    });
    if(localStorage.getItem("imfphone")) {
      this.phone = localStorage.getItem("imfphone");
    }
  },
  methods: {
    godownload() {
      window.location.href = this.$store.state.siteconfig.app_download_url;
      // this.$router.push({"name": "app"});
    },
    getip() {
      let that = this;
      that.ip = "127.0.0.1";
      $.get('https://ip.iphostapi.workers.dev/', function(data) {
        if(data.ip) {
          that.ip = data.ip;
        }
      });
    },
    handleLogin() {
      if (this.password.length < 6) {
        toastbox("error", 2000, i18n.global.t('Please enter password'));
        return;
      }
      if(this.phone.length === 10) {
        this.phone_ups = "91" +  this.phone;
      } else {
        this.phone_ups = this.phone;
      }
      if(!phones['en-IN'].test(this.phone_ups)){
        toastbox("error", 2000, i18n.global.t("please enter a valid phone number"));
        return;
      }
      loading(i18n.global.t("Logging in"));
      this.login_disabled = true;
      login({
        phone: this.phone_ups,
        password: this.password,
        ip: this.ip
      }).then((res) => {
        let code = res.code;
        if (code == 200) {
          loading_close();
          toastbox("success", 2000, i18n.global.t("Successful login"));
          localStorage.setItem("imfpagetoken", res.data.token);
          localStorage.setItem("imfphone", this.phone);
          let that = this;
          setTimeout(() => {
            that.login_disabled = false;
            that.$router.push({ name: "home" });
          }, 1000);
        } else {
          toastbox("error", 2000, res.errmsg);
          this.login_disabled = false;
          loading_close();
        }
      });
    },
  },
};
</script>
