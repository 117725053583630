<template>
  <Header></Header>
  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="blog-post">
        <div class="mt-2">
            <h2 class="title">{{title}}</h2>
        </div>
      
      <div class="post-header">
        {{datetime}}
      </div>
      <div class="post-body" v-html="content">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import { getnewsIddetail, getnewsCodedetail } from "../common/api";
export default {
  name: "News",
  components: {
    Header,
  },
  data() {
    return {
      content: "",
      title: "",
      datetime: "",
      news_id: "",
    }
  },
  created() {
    console.log(this.$router.currentRoute.value.params);
    this.news_id = this.$router.currentRoute.value.params.id;
  },
  mounted() {
    if(this.isnumber(this.news_id)) {
      getnewsIddetail(this.news_id).then((res) => {
        if (res.code === 200) {
          this.content = res.data.content;
          this.title = res.data.name;
          this.datetime = res.data.ctime;
        }
      });
    } else {
      getnewsCodedetail(this.news_id).then((res)=>{
        if (res.code === 200) {
          this.content = res.data.content;
          this.title = res.data.name;
          this.datetime = res.data.ctime;
        }
      })
    }
  },
  methods: {
    isnumber(str) {
      var n = Number(str);
      if (!isNaN(n))
      {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

<style scoped>
.blog-post .post-header {
    font-size: 11px;
    padding: 10px 0;
    margin: 0px 16px;
    color: #A1A1A2;
    display: inherit;
    align-items: center;
    justify-content: space-between;
    border-top: 1px none;
    border-bottom: none;
    text-align: right;
}
</style>