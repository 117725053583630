<template>
  <Header :title="$t('Withdraw')"></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="section mt-3 mb-3">
      <div class="card text-white bg-warning mb-2">
          <div class="card-header bank-header">
            <span>{{$t("Withdrawal information")}}</span>
            <div class="bank-add-edit" v-if="ifisaddedit==='add'" @click="showaddedit">
              <ion-icon class="bank-add" name="add-circle-outline"></ion-icon>
              <span class="add-text">{{$t("Add")}}</span>
            </div>
            <div class="bank-add-edit" v-if="ifisaddedit==='edit'" @click="showaddedit">
              <ion-icon class="bank-add" name="create-outline"></ion-icon>
              <span class="add-text">{{$t("Edit")}}</span>
            </div>
          </div>
          <div class="card-body" v-if="ifisaddedit==='edit'">
              <h5 class="card-title">{{name}}</h5>
              <h5 class="card-title">{{format_card_number(card_number)}}</h5>
              <p class="card-text">{{bank}}</p>
              <p class="card-text">IFSC: {{format_card_number(ifsc)}}</p>
          </div>
          <div class="card-body" v-if="ifisaddedit==='add'" @click="showaddedit">
              <h5 class="card-title">{{$t("Please add withdrawal information first")}}</h5>
          </div>
          
      </div>

      <div class="card">
        <div class="card-body justify-content-between align-items-end">
          <div>
            <p class="withdrawinfo" v-html="withdrawinfo"></p>
            <!-- <p class="withdrawinfo"></p> -->
            <h6 class="card-subtitle">{{$t("Balance")}}: </h6>
            <h5 class="card-title mb-0 d-flex align-items-center justify-content-between">
              {{$t("$")}}{{ $store.state.investorinfo.balance }}
            </h5>
          </div>

          <div>
            <div class="text-left mt-3">
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label withdrawamount" for="amount">{{$t("Withdraw amount")}}({{$t("Tax")}}: 6%)</label>
                        <input type="number" class="form-control" v-model="withdraw_amount"
                            :placeholder="$t('Please enter withdraw amount')">
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                </div>
            </div>
          </div>

          <button type="button" class="btn btn-primary btn-lg btn-block" @click="handlewithdraw">{{$t("Withdraw")}}</button>
        </div>
      </div>
    </div>
  </div>


  <!-- Dialog Form -->
  <div class="modal fade dialogbox" id="addbank" data-backdrop="static" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">{{$t("Withdrawal information")}}</h5>
              </div>
              <form>
                  <div class="modal-body text-left mb-2">

                      <div class="form-group basic">
                          <div class="input-wrapper">
                              <label class="label" for="name">{{$t("Name")}}</label>
                              <input type="text" class="form-control" id="name" v-model="add_name" :placeholder="$t('Name')">
                              <i class="clear-input">
                                  <ion-icon name="close-circle"></ion-icon>
                              </i>
                          </div>
                      </div>

                      <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="bank">{{$t("Bank Name")}}</label>
                                <input type="text" class="form-control" id="bank" v-model="add_bank" :placeholder="$t('Bank Name')">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                      <div class="form-group basic">
                          <div class="input-wrapper">
                              <label class="label" for="card_number">{{$t('Bank Numbers')}}</label>
                              <input type="Number" class="form-control" id="card_number" v-model="add_card_number"
                                  :placeholder="$t('Bank Numbers')">
                              <i class="clear-input">
                                  <ion-icon name="close-circle"></ion-icon>
                              </i>
                          </div>
                      </div>

                      <div class="form-group basic">
                          <div class="input-wrapper">
                              <label class="label" for="bank">{{$t("IFSC")}}</label>
                              <input type="text" class="form-control" id="bank" v-model="add_ifsc" :placeholder="$t('IFSC')">
                              <i class="clear-input">
                                  <ion-icon name="close-circle"></ion-icon>
                              </i>
                              <div>{{$t('IFSC Point')}}</div>
                          </div>
                      </div>

                  </div>
                  <div class="modal-footer">
                      <div class="btn-inline">
                          <button type="button" class="btn btn-text-secondary" data-dismiss="modal">{{$t("CLOSE")}}</button>
                          <button type="button" class="btn btn-text-primary" @click="addeditbank">{{$t("Done")}}</button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
  <!-- * Dialog Form -->

  <BottomMenu></BottomMenu>
  <SuccessErr></SuccessErr>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BottomMenu from "../components/BottomMenu.vue";
import SuccessErr from "../components/SuccessErr.vue";
import i18n from '../lang/index';
import { withdraw, addwithdraw, editwithdraw, getbankinfo, getnewsCodedetail } from "../common/api";
export default {
  name: "Withdraw",
  data() {
    return {
      ifisaddedit: "add",
      withdraw_amount: "",
      name: "",
      card_number: "",
      bank: "",
      ifsc: "",
      add_name: "",
      add_card_number: "",
      add_bank: "",
      add_ifsc: "",
      id: "",
      withdrawinfo: "",
    };
  },
  components: {
    Header,
    BottomMenu,
    SuccessErr
  },
  created() {
    this.$store.dispatch('asyncupdateinvestorinfo');
    getnewsCodedetail("withdrawinfo").then((res)=>{
      if(res.code === 200) {
        this.withdrawinfo = res.data.content;
      }
    });
  },
  mounted() {
    this.getbank();
  },
  methods: {
    initdata(data) {
      this.id = data.id;
      this.name = data.name;
      this.card_number = data.card_number;
      this.bank = data.bank;
      this.ifsc = data.ifsc;
    },
    format_card_number(card_number) {
      if(card_number === undefined) {
        return "";
      }
      let card_number_len = card_number.length;
      return "**** **** **** " + card_number.slice(card_number_len - 4, card_number_len)
    },
    showaddedit() {
      if(this.ifisaddedit === "edit") {
        this.add_name = this.name;
        this.add_bank = this.bank;
        this.add_card_number = this.card_number;
        this.add_ifsc = this.ifsc;
      }
      $("#addbank").modal();
    },
    getbank() {
      getbankinfo().then((res)=>{
      if(res.code===200) {
        this.ifisaddedit = "edit";
        this.id = res.data.id;
        this.name = res.data.name;
        this.card_number = res.data.card_number;
        this.bank = res.data.bank;
        this.ifsc = res.data.ifsc;
      } else if(res.code === 404) {
        this.ifisaddedit = "add";
      }
    })
    },
    addeditbank() {
      if(this.add_name.length < 3) {
        toastbox("error", 2000, i18n.global.t('Please enter a name'));
        return;
      }

      if(this.add_card_number.length < 3) {
        toastbox("error", 2000, i18n.global.t('Please enter bank card number'));
        return;
      }

      if(this.add_bank.length < 3) {
        toastbox("error", 2000, i18n.global.t('Please enter the bank'));
        return;
      }

      if(this.add_ifsc.length < 11) {
        toastbox("error", 2000, i18n.global.t('Invalid IFSC'));
        return;
      }
      loading(i18n.global.t("In process"));
      if(this.ifisaddedit === "add") {
        addwithdraw({
          name: this.add_name,
          card_number: this.add_card_number,
          bank: this.add_bank,
          ifsc: this.add_ifsc
        }).then((res)=>{
          loading_close();
          if(res.code===200) {
            toastbox("success", 2000, i18n.global.t('Successfully'));
            $("#addbank").modal("hide");
            this.initdata(res.data);
            this.ifisaddedit = "edit";
          } else {
            toastbox("error", 2000, res.errmsg);
          }
        })
      } else {
        editwithdraw(this.id, {
          name: this.add_name,
          card_number: this.add_card_number,
          bank: this.add_bank,
          ifsc: this.add_ifsc
        }).then((res)=>{
          if(res.code===200) {
            toastbox("success", 2000, i18n.global.t('Successfully'));
            $("#addbank").modal("hide");
            this.initdata(res.data);
          } else {
            toastbox("error", 2000, res.errmsg);
          }
          loading_close();
        })
      }
    },
    handlewithdraw() {
      if(this.ifisaddedit === "add") {
        toastbox("error", 2000, i18n.global.t('Please add withdrawal information first'));
        return;
      }
      if(this.withdraw_amount==="" || Number(this.withdraw_amount) > Number(this.$store.state.investorinfo.balance)) {
        toastbox("error", 2000, i18n.global.t('Insufficient balance'));
        return;
      }
      loading(i18n.global.t("Withdrawal in progress"));
      withdraw({
        amount: this.withdraw_amount
      }).then((res)=>{
        if(res.code === 200) {
          loading_close();
          toastbox("success", 2000, i18n.global.t('Successfully submitted application, please wait for review'));
          this.withdraw_amount = 0;
          this.$store.dispatch('asyncupdateinvestorinfo');
          let that=this;
          setTimeout(function(){
            that.$router.push("/my");
          }, 2000);
        } else {
          toastbox("error", 2000, res.errmsg);
          loading_close();
        }
      })
    }
  },
};
</script>

<style scoped>
.card .card-body {
    padding: 24px 16px;
    line-height: 1.4em;
    text-align: left;
}
.bank-header {
  text-align: left;
}
.bank-add-edit {
  display: inline;
  position: absolute;
  right: 37px;
}
.bank-add {
  width: 22px;
  height: 22px;
}
.add-text {
  position: absolute;
}
.withdrawinfo {
  font-size: 8px;
}
.withdrawamount {
  font-size: 1.2rem;
  margin: 0;
  font-weight: 800;
  color: #5ac905;
  display: block;
  line-height: 1.2em;
  text-align: left;
}
</style>