<template>
    <div class="mt-0">
    <ul class="listview image-listview media mb-2" v-infinite-scroll="loadMore"
        v-infinite-scroll-distance="10">
        <li v-if="fund_list.length===0">
            <div class="in">
                {{$t("No data")}}
            </div>
        </li>
        <li v-for="item in fund_list" v-bind:key="item.id">
            <a href="javascript:;" class="item">
                <div class="imageWrapper" @click="$router.push('/funddetail/' + item.id)">
                    <img :src="$store.state.siteconfig.images_host + item.images" onerror="this.src='assets/img/fund.webp';this.onerror=null" alt="image" class="imaged w64">
                </div>
                <div class="in">
                    <div @click="$router.push('/funddetail/' + item.id)">
                        <div style="text-align: left;">{{item.name}}</div>
                        <div class="text-muted">{{$t('Daily income')}}: {{item.interest_rate}}%</div>
                        <div class="text-muted-cycle">{{$t("Cycle")}}: {{item.cycle}} days</div>
                        <div class="text-muted">{{$t("Quantity")}}: {{item.every_buy_max_times}}</div>
                        <div class="text-muted-cycle">{{$t("Quota")}}: {{$t("$")}}{{item.quota}}</div>
                    </div>
                    <div>
                        <p class="initial_amount">₹{{item.initial_amount}}</p>
                        <button type="button" class="btn btn-outline-primary buy rounded shadowed mr-1 mb-1" disabled="disabled" v-if="!item.is_onsale">{{$t("Buy")}}</button>
                        <button type="button" class="btn btn-outline-primary buy rounded shadowed mr-1 mb-1" @click="showmodal(item)" v-else>{{$t("Buy")}}</button>
                    </div>
                </div>
            </a>
            <div v-if="JSON.stringify(item.buyinfo) !== '{}'">
            <p class="buyinfo">
              <span>{{$t("Start time")}}: {{item.buyinfo.last_buy_time}}</span>
              <span>{{$t("Expire date")}}: {{item.buyinfo.expire_date}}</span>
            </p>
            <p class="buyinfo">
              <span>{{$t("Principal Amount")}}: {{$t('$')}}{{item.buyinfo.total_principal_amount}}</span>
              <span>{{$t("Income")}}: {{$t('$')}}{{item.buyinfo.total_investment_interest}}</span>
              <span>{{$t("Quantity")}}: {{item.buyinfo.total_buy_times}}</span>
            </p>
            </div>
            <div class="progress fund-progress ">
                <div class="progress-bar" role="progressbar" :style="'width: ' + String(item.progress) + '%;'" :aria-valuenow="item.progress" aria-valuemin="0" aria-valuemax="100">
                  <span v-if="item.is_showprogress">{{item.progress}}%</span>
                </div>
            </div>
        </li>
        <li>
          <div class="spinner-border text-info mt-2" role="status" v-if="loading_show"></div>
        </li>
        <BuyFund :curr_fund="curr_fund" @refulshfundlist="refulshfundlist"></BuyFund>
    </ul>
    </div>
</template>
<script>
import BuyFund from "./BuyFund.vue";
import { getprojectlist } from "../common/api";
import i18n from "../lang/index";
export default {
  name: "FundList",
  components: {
    BuyFund
  },
  data() {
    return {
        curr_fund: {},
        fund_list: [],
        loading: false,
        loading_show: true,
        page: 1,
        max_page: 0,
        limit: 20,
        no_more: false,
    }
  },
  created() {
    this.$store.dispatch('asyncupdateinvestorinfo');
    this.loadMore();
  },
  methods: {
    set_max_page(count) {
        if(this.max_page !== 0) {
            return;
        }
        if (count % this.limit == 0) {
            this.max_page = parseInt(count / this.limit);
        } else {
            this.max_page = parseInt(count / this.limit) + 1;
        }
    },
    loadMore() {
      if(this.loading || this.no_more) {
          return;
      } else {
          this.loading = true;
          this.loading_show = true;
          this.get_list();
      }
    },
    refulshfundlist() {
      this.page = 1;
      this.loading = false;
      this.no_more = false;
      this.fund_list = [];
      this.loadMore();
    },
    get_list() {
      getprojectlist({
          page: this.page,
          limit: this.limit,
      }).then((res)=>{
          if (res.code === 200) {
              this.set_max_page(res.data.count);
              if (res.data.result.length < this.limit || this.page >= this.max_page) {
                this.no_more = true;
                this.loading_show = false;
              } else {
                this.page = this.page + 1;
                this.loading = false;
                this.loading_show = false;
              }
              this.fund_list.push(...res.data.result);
              for(let item of res.data.result) {
                if(item.type === "reward") {
                  this.$store.commit("updaterewardfund", item.id);
                }
              }
          }
      });
    },
    showmodal(curr_fund) {
      this.curr_fund = curr_fund;
      if (this.$store.state.investorinfo.balance !== undefined) {
        $("#investormodal").modal();
      } else {
        toastbox("error", 2000, i18n.global.t("Please log in to invest"));
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      }
    }
  }
};
</script>

<style scoped>
.btn {
  height: 33px;
}
.progress-bar {
  background-color: #e9e9e9;
  background-image: linear-gradient(59deg,#9accff,#0060ff);
}

.listview-title {
  color: #1E74FD;
  padding: 1px 14px;
}
.listview .text-muted {
  font-size: 13px;
  color: red !important;
  text-align: left;
}
.image-listview > li a.item:after {
  background: none;
  content: none;
}
.listview .text-muted-cycle {
  font-size: 13px;
  color: #A1A1A2 !important;
  text-align: left;
}
.fund-progress {
  margin:5px 10px;
}
.imaged.w64 {
    width: 64px !important;
    height: 64px !important;
    margin-right: 10px;
}
.wrapper {
  height: calc(100vh - 55px);
  overflow: scroll;
}
.buyinfo {
  font-size: .85em;
  margin-bottom: 0rem;
  color: #586d7f !important;
}
.buyinfo span {
  margin-right: 15px;
}
.buy {
  position: absolute;
  right: 30px;
  bottom: 16px;
}
.initial_amount {
  position: absolute;
  right: 42px;
  top: 30px;
  width: 56px;
}
</style>