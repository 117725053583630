<template>
  <Header :title="$t('Fund detail')"></Header>
  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="blog-post">
        <div class="fundtitle mt-1">
          <div class="imageWrapper">
            <img :src="getimgurl(funddetail.images)" alt="image" class="fundimage imaged square w-100">
          </div>
          <div class="item">
            <h2 class="title">{{funddetail.name}}</h2>
            <h3>{{$t("Quota")}}: <strong>{{$t("$")}}{{funddetail.quota}}</strong></h3>
          </div>
        </div>
        <div class="profile-stats pl-2 pr-2 mt-1">
            <a href="javascript:;" class="item">
                <strong>{{$t("$")}}{{funddetail.initial_amount}}</strong>
                {{$t("Initial amount")}}
            </a>
            <a href="javascript:;" class="item">
                <strong>{{funddetail.interest_rate}}%</strong>
                {{$t("Daily income")}}
            </a>
            <a href="javascript:;" class="item">
                <strong>{{funddetail.cycle}} <span style="font-size: .1rem">{{$t("days")}}</span></strong>
                {{$t("Cycle")}}
            </a>
            <a href="javascript:;" class="item">
                <strong>{{funddetail.every_buy_max_times}}</strong>
                {{$t("Quantity")}}
            </a>
        </div>
        <!-- <div class="section-title ml-1 mt-1">{{$t("progress")}}</div> -->
        <div class="progress fund-progress ml-1 mr-1">
            <div class="progress-bar" role="progressbar" :style="'width: ' + String(funddetail.progress) + '%;'" :aria-valuenow="funddetail.progress"
                aria-valuemin="0" aria-valuemax="100"><span v-if="funddetail.is_showprogress">{{funddetail.progress}}%</span></div>
        </div>
        <div class="section-title ml-1 mt-1">{{$t("Introduction to the Fund")}}</div>
        <div class="post-body" v-html="funddetail.desc">
        </div>
        <button type="button" class="btn btn-secondary btn-lg btn-block buy-b" disabled="disabled" v-if="!funddetail.is_onsale">{{$t("Buy")}}</button>
        <button type="button" class="btn btn-primary btn-lg btn-block buy-b" @click="showmodal()" v-else>{{$t("Buy")}}</button>
        <BuyFund :curr_fund="funddetail"></BuyFund>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BuyFund from "../components/BuyFund.vue";
import { getProjectdetail } from "../common/api";
export default {
  name: "FundDetail",
  components: {
    Header,
    BuyFund
  },
  data() {
    return {
      funddetail: {},
    }
  },
  created() {
    this.fund_id = this.$router.currentRoute.value.params.id
  },
  mounted() {
    getProjectdetail(this.fund_id).then((res)=>{
        if(res.code === 200) {
            this.funddetail = res.data;
        }
    })
  },
  methods: {
    getimgurl(url) {
      if(url) {
        return this.$store.state.siteconfig.images_host + url;
      } else {
        return 'assets/img/fund.webp';
      }
    },
    showmodal(){
      if (this.$store.state.investorinfo.balance !== undefined) {
        $("#investormodal").modal();
      } else {
        toastbox("error", 2000, i18n.global.t("Please log in to invest"));
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      }
    }
  }
};
</script>

<style scoped>
.blog-post .post-header {
    font-size: 11px;
    padding: 10px 0;
    margin: 0px 16px;
    color: #A1A1A2;
    display: inherit;
    align-items: center;
    justify-content: space-between;
    border-top: 1px none;
    border-bottom: none;
    text-align: right;
}
.buy-b {
    position: fixed;
    bottom: 0px;
    left: 0px;
}
.fundimage {
  max-width: 100px;
  padding-left: 10px;
}
.fundtitle
{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.fundtitle > h2 {
  position: relative;
  left: 10px;
  top: 38px;
  font-size: 18px;
  text-align: center;
}
.fundtitle > h3 {
  font-size: 15px;
  position: relative;
  top: 34px;
  left: 10px;
}

.mt-6 {
  margin-top: 57px;
}
</style>