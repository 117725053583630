import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import ForgotpasswordView from '../views/Forgotpassword.vue';
import News from '../views/News.vue';
import NewsDetail from '../views/NewsDetail.vue';
import Fund from '../views/Fund.vue';
import Team from '../views/Team.vue';
import My from '../views/My.vue';
import CapitalDetails from '../views/CapitalDetails.vue';
import RegchargeDetails from '../views/RegchargeDetails.vue';
import WithdrawDetails from '../views/WithdrawDetails.vue';
import TransactionDetails from '../views/TransactionDetails.vue';
import ChangePassword from '../views/ChangePassword.vue';
import Recharge from '../views/Recharge.vue';
import Withdraw from '../views/Withdraw.vue';
import FundDetail from '../views/FundDetail.vue';
import TeamDetail from "../views/TeamDetail.vue";
import CommissionsDetail from "../views/CommissionsDetail.vue";
import RechargeResult from "../views/RechargeResult.vue";
import RegSuccess from "../views/RegSuccess.vue";
import ValueaddDetails from "../views/ValueaddDetails.vue";
import MyFundsDetails from "../views/MyFundsDetails.vue";
import MyfundDetail from "../views/MyfundDetail.vue";
import RewardFund from "../views/RewardFund.vue";
import AppView from "../views/AppView.vue";


const routes = [
  {
    path: '/',
    name: 'home1',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/registerrc/:referral_code',
    name: 'register_referral_code',
    component: RegisterView
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotpasswordView
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/newsdetail/:id',
    name: 'newsdetail',
    component: NewsDetail
  },
  {
    path: '/fund',
    name: 'fund',
    component: Fund
  },
  {
    path: '/funddetail/:id',
    name: 'funddetail',
    component: FundDetail
  },
  {
    path: '/team',
    name: 'team',
    component: Team
  },
  {
    path: '/commissionsdetail',
    name: 'commissionsdetail',
    component: CommissionsDetail
  },
  {
    path: '/teamdetail',
    name: 'teamdetail',
    component: TeamDetail
  },
  {
    path: '/capitaldetails',
    name: 'capitaldetails',
    component: CapitalDetails
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: Recharge
  },
  {
    path: '/rechargedetails',
    name: 'rechargedetails',
    component: RegchargeDetails
  },
  {
    path: '/rechargeresult/:orderno',
    name: 'rechargeresult',
    component: RechargeResult
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: Withdraw
  },
  {
    path: '/valueadddetails',
    name: 'valueadddetails',
    component: ValueaddDetails,
  },
  {
    path: '/withdrawdetails',
    name: 'withdrawdetails',
    component: WithdrawDetails
  },
  {
    path: '/transactiondetails',
    name: 'transactiondetails',
    component: TransactionDetails
  },
  {
    path: '/myfunddetail/:itemid',
    name: 'myfunddetail',
    component: MyfundDetail
  },
  {
    path: '/rewardfunddetail/:itemid',
    name: 'rewardfunddetail',
    component: RewardFund
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: ChangePassword
  },
  {
    path: '/my',
    name: 'my',
    component: My
  },
  {
    path: '/RegSuccess',
    name: 'RegSuccess',
    component: RegSuccess
  },
  {
    path: '/MyFundsDetails',
    name: 'MyFundsDetails',
    component: MyFundsDetails
  },
  {
    path: '/app',
    name: 'app',
    component: AppView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: process.env.NODE_ENV === "app" ? createWebHashHistory() :  createWebHistory(),
  routes
});

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
  // if ($("#android-add-to-home-screen").length > 0) { 
  //   // 存在
  // }
  $("#android-add-to-home-screen").modal("hide");
  $("#addbank").modal("hide");
  $("#investormodal").modal("hide");
  to.params.from = from.name;
  to.params.to = to.name;
	// 判断有没有登录
	if (!localStorage.getItem('imfpagetoken')) {
		if (to.name === "login" || to.name === "RegSuccess" || to.name === "app" || to.name === "register" || to.name === "forgotpassword" || 
        to.name === "home" || to.name === "home1" || to.name === "news" || to.name === "newsdetail" ||
        to.name === "register_referral_code") {
			next();
		} else {
			router.push('login');
		}
	} else {
		next();
	}
});

export default router
