export default {
    "$": "₹",

    //home
    "Company": "कंपनी",
    "My Team": "मेरी टीम",
    "Recharge": "फिर से दाम लगाना",
    "Recharge details": "रिचार्ज विवरण",
    "Fund": "निधि",
    "Hot fund": "हॉट फंड",
    "Contact": "संपर्क करना",
    "Insights": "इनसाइट्स",
    "Market Insights": "बाजार अंतर्दृष्टि",
    "The ‘Great Disconnect’: Can investors find opportunity in disagreement?": "'महान डिस्कनेक्ट': क्या निवेशकों को असहमति में अवसर मिल सकता है?",
    "Read More": "अधिक पढ़ें",
    "About us": "हमारे बारे में",
    "New Release Notice": "नई रिलीज सूचना",
    "A new version is detected, please update now": "एक नए संस्करण का पता चला है, कृपया अभी अपडेट करें",
    "Update Later": "बाद में अपडेट करें",
    "Update Now": "अभी अद्यतन करें",

    //news
    "Detail": "विवरण",
    "NEWS": "समाचार",
    "News": "समाचार",

    //fund
    "Insufficient balance": "अपर्याप्त शेषराशि",
    "Please log in to invest": "कृपया निवेश करने के लिए लॉग इन करें",
    "Today's earnings": "आज की कमाई",
    "Buy": "खरीदें",
    "Minimum investment amount": "न्यूनतम निवेश राशि",
    "Investment amount": "निवेश राशि",
    "No data": "कोई डेटा नहीं",
    "Total investment": "कुल निवेश",
    "Total interest": "कुल ब्याज",
    "Today's interest": "आज की रुचि",
    "Total Copies": "कुल प्रतियां",
    "Frequency": "आवृत्ति",
    "Exceeded maximum frequency": "अधिकतम आवृत्ति से अधिक",
    "Quantity": "मात्रा",
    "Start time": "समय शुरू",
    "Expire date": "समाप्त होने की तिथि",
    "Date": "दिनांक",
    "Income": "आमदनी",
    "View details": "विवरण देखें",
    "Fund info": "फंड की जानकारी",
    "Interest Details": "रुचि विवरण",
    "Introduction to the Fund": "फंड परिचय",
    "Quota": "quota",
    "Invitation bonus fund": "आमंत्रण बोनस फंड",
    "Reward History": "इनाम इतिहास",
    "No reward yet, invite friends to invest together to get Invitation bonus fund": "अभी तक कोई इनाम नहीं, आमंत्रण बोनस फंड प्राप्त करने के लिए दोस्तों को एक साथ निवेश करने के लिए आमंत्रित करें",
    "Previous": "पिछला",
    "Next": "अगला",
    "Daily rate of return": "दैनिक रिटर्न दर",
    "Total revenue": "कुल मुनाफा",
    "Get lucky earnings": "भाग्यशाली कमाई प्राप्त करें",


    //fund detail
    "Fund detail": "फंड विवरण",
    "Daily income": "दैनिक आय",
    "days": "दिन",
    "Cycle": "चक्र",

    //my
    "My": "मेरे",
    "Email": "ईमेल",
    "Amount": "राशि",
    "Add value today": "आज ही मूल्य जोड़ें",
    "Recharge": "फिर से दाम लगाना",
    "Withdraw": "वापस लेना",
    "Capital details": "पूंजी विवरण",
    "Withdraw details": "विवरण वापस लें",
    "Purchase History": "खरीद इतिहास",
    "Sign Out": "साइन आउट",
    "Confirm to log out": "लॉग आउट करने की पुष्टि करें",
    "Confirm to log out of the current account": "चालू खाते से लॉग आउट करने की पुष्टि करें",
    "CLOSE": "बंद करे",
    "OK": "है ठीक",
    "value added treasure": "मूल्य वर्धित खजाना",
    "Balance": "संतुलन",
    "Assets": "आस्तियां",
    "Transfer in": "में स्थानांतरण",
    "Transfer amount": "राशि में स्थानांतरण",
    "Register time": "पंजीकरण समय",
    "Notice": "नोटिस",
    "Holding fund": "होल्डिंग फंड",

    //recharge
    "Recharge Details": "रिचार्ज विवरण",
    "Recharge amount": "रिचार्ज राशि",
    "The recharge amount cannot be 0": "रिचार्ज राशि 0 . नहीं हो सकती",
    "Successfully Recharge": "सफलतापूर्वक रिचार्ज",
    "Recharge failed": "रिचार्ज विफल",
    "Recharging in progress": "रिचार्जिंग जारी है",
    "Recharge Again": "फिर से रिचार्ज",
    "Payment Tutorial": "भुगतान ट्यूटोरियल",
    "Recharge Tutorial":"रिचार्ज ट्यूटोरियल",
    "please enter the amount to recharge": "कृपया रिचार्ज करने के लिए राशि दर्ज करें",
    "Payment method": "भुगतान का तरीका",


    //rechargeListBox
    "All": "सभी",
    "Recharge": "फिर से दाम लगाना",
    "Withdraw": "वापस लेना",
    "Lucky Earnings": "भाग्यशाली कमाई",
    "Interest": "रुचि",
    "Reward": "इनाम",
    "Investment": "निवेश",
    "Principal Amount": "मूल राशि",
    "Commission": "आयोग",
    "Treasure Deposit": "खजाना जमा",
    "Treasure Takeout": "खजाना टेकआउट",
    "Withdrawal Return": "निकासी वापसी",

    //withdraw
    "Withdraw amount": "राशि वापस ले",
    "Successfully submitted application, please wait for review": "सफलतापूर्वक आवेदन जमा किया गया, कृपया समीक्षा की प्रतीक्षा करें",
    "Withdrawal failed": "निकासी विफल",
    "Withdrawal information": "निकासी की जानकारी",
    "Add": "जोड़ें",
    "Edit": "संपादन करना",
    "Please add withdrawal information first": "कृपया पहले निकासी जानकारी जोड़ें",
    "Name": "नाम",
    "Bank Numbers": "बैंक नंबर",
    "Done": "हो गया",
    "Bank Name": "बैंक का नाम",
    "Please enter a name": "कृपया एक नाम दर्ज करें",
    "Please enter bank card number": "कृपया बैंक कार्ड नंबर दर्ज करें",
    "Please enter the bank": "कृपया बैंक में प्रवेश करें",
    "Successfully": "सफलतापूर्वक",
    "IFSC": "IFSC",
    "Invalid IFSC": "अमान्य IFSC",
    "IFSC Point": "कृपया 11 सही IFSC दर्ज करें",
    "Withdrawal time: Every week: Monday, Tuesday, Wednesday, Thursday, Friday at 9:00 am to 6:00 PM": "वापसी का समय: हर सप्ताह: सोमवार, मंगलवार, बुधवार, गुरुवार, शुक्रवार को सुबह 9:00 बजे से शाम 6:00 बजे तक",
    "Tax": "कर",
    "Application Amount": "आवेदन राशि",
    "Reason": "कारण",
    "Please enter withdraw amount": "कृपया निकासी राशि दर्ज करें",

    //login
    "Fill the form to log in": "लॉग इन करने के लिए फॉर्म भरें",
    "Email address": "ईमेल पता",
    "Password": "पासवर्ड",
    "Register Now": "अभी पंजीकरण करें",
    "Forgot Password?": "पासवर्ड भूल गए?",
    "Log in": "लॉग इन करें",
    "CLOSE": "बंद करना",
    "Please enter password": "कृप्या पास्वर्ड भरो",
    "please input your email": "कृपया अपना ईमेल इनपुट करें",
    "Successful login": "सफल प्रवेश",
    "Download mobile app login": "मोबाइल ऐप डाउनलोड करें लॉगिन",

    //forgotpassword
    "Login": "लॉग इन करें",
    "Reset Password": "पासवर्ड रीसेट",
    "Receive verification code to change password": "पासवर्ड बदलने के लिए सत्यापन कोड प्राप्त करें",
    "Reset": "रीसेट",
    "Verify identidy": "पहचान सत्यापित करें",
    "Initial amount": "प्रारंभिक रकम",
    "progress": "प्रगति",
    "Password reset successfully, please log in again": "पासवर्ड सफलतापूर्वक रीसेट हो गया, कृपया फिर से लॉग इन करें",
    "Authentication passed": "प्रमाणीकरण पारित",

    //change password
    "Please enter the correct format password": "कृपया सही प्रारूप पासवर्ड दर्ज करें",
    "success": "सफलता",
    "OK": "ठीक है",
    "Change Password": "पासवर्ड बदलें",
    "Successfully modified": "सफलतापूर्वक संशोधित",
    "Confirm modification": "संशोधन की पुष्टि करें",
    "The passwords entered twice do not match": "दो बार दर्ज किए गए पासवर्ड मेल नहीं खाते",

    //Team
    "My invitation": "मेरा निमंत्रण",
    "Copy": "कॉपी",
    "Copy successfully": "सफलतापूर्वक कॉपी करें",
    "Copy failed": "कॉपी विफल",
    "Full details": "पूरा विवरण",
    "Today's details": "आज का विवरण",
    "Team": "दल",
    "My Team": "मेरी टीम",
    "Commissions": "आयोगों",
    "Commission details": "आयोग का विवरण",
    "Commissions Total": "कुल कमीशन",
    "Commission Today": "आयोग आज",
    "Commission Transferable": "कमीशन हस्तांतरणीय",
    "Transfer out": "बाहर स्थानांतरण",
    "Team Details": "टीम विवरण",
    "Buying fund staff": "फंड स्टाफ खरीदना",
    "Member purchase amount": "सदस्य खरीद राशि",
    "Insufficient commission": "अपर्याप्त कमीशन",
    "Recharge reward": "रिचार्ज इनाम",
    "Commission": "आयोग",
    "Total": "टोटल",
    "When your lower-level members buy funds, you can get 7% commission of the purchase amount for level-A members, 3% for level-B members, and 2% for level-C members": "जब आपके निचले स्तर के सदस्य धन खरीदते हैं, तो आप ए-स्तर के सदस्यों के लिए खरीद राशि का 7%, बी-स्तर के सदस्यों के लिए 3% और सी-स्तर के सदस्यों के लिए 2% प्राप्त कर सकते हैं।",
    "When your level-A member successfully recharges for the first time, you can get a 5% commission on the recharge amount": "जब आपका ए-लेवल सदस्य पहली बार सफलतापूर्वक रिचार्ज करता है, तो आप रिचार्ज राशि पर 5% कमीशन प्राप्त कर सकते हैं",

    //register
    "Send SMS": "संदेश भेजो",
    "Register": "पंजीकरण करवाना",
    "Fill the form to join us": "हमसे जुड़ने के लिए फॉर्म भरें",
    "Username": "उपयोगकर्ता नाम",
    "Full name": "पूरा नाम",
    "Please fill in a valid username": "कृपया एक मान्य उपयोगकर्ता नाम भरें",
    "Phone": "फ़ोन",
    "Captcha": "कॅप्चा",
    "Password": "पासवर्ड",
    "Password (again)": "पासवर्ड फिर से",
    "Inviter code": "आमंत्रितकर्ता कोड",
    "Please type in your name": "कृपया अपना नाम टाइप करें",
    "Please enter password": "कृप्या पास्वर्ड भरो",
    "Please enter a password of at least 6 characters, including at least 1 uppercase letter and 1 lowercase letter to ensure the security of your account": "अपने खाते की सुरक्षा सुनिश्चित करने के लिए कृपया कम से कम 6 अक्षरों का पासवर्ड दर्ज करें, जिसमें कम से कम 1 अपरकेस अक्षर और 1 लोअरकेस अक्षर शामिल है",
    "The passwords entered twice do not match": "दो बार दर्ज किए गए पासवर्ड मेल नहीं खाते",
    "please enter a valid phone number": "कृपया एक मान्य फोन नंबर दर्ज करें",
    "please enter verification code": "कृपया सत्यापन कोड डालें",
    "Sent successfully": "सफलतापूर्वक भेज दिया",
    "Resend": "फिर से भेजें",
    "Send SMS": "एसएमएस भेजें",
    "Order Number": "आदेश संख्या",
    "Go Back": "वापस जाओ",
    "The registration is successful, please download the app to log in": "पंजीकरण सफल रहा, कृपया लॉग इन करने के लिए ऐप डाउनलोड करें",

    // loading
    "Logging in": "प्रवेश किया",
    "In process": "प्रक्रिया में",
    "Registration in progress": "पंजीकरण चल रहा है",
    "Recharging in progress": "रिचार्जिंग जारी है",
    "Withdrawal in progress": "निकासी की प्रक्रिया चल रही है",
    "Loading...": "Loading...",
    "no more data": "कोई और डेटा नहीं",

    //successful registration page
    "successful registration": "सफल पंजीकरण",
    "Thank you for your registration, you can log in to your account, or download the APP.": "आपके पंजीकरण के लिए धन्यवाद, आप अपने खाते में लॉग इन कर सकते हैं, या एपीपी डाउनलोड कर सकते हैं।",
    "Go Login": "लॉग इन करें",
    "Download App": "ऐप डाउनलोड करें",
    "success": "सफल",
    "Please click the download button to download the APP.": "कृपया डाउनलोड बटन पर क्लिक करें एपीपी डाउनलोड करने के लिए।",

    //check-in calendar
    "Check In": "चेक इन",
    "signed in today": "आज साइन इन किया",
    "Check in succeeded": "चेक इन सफल",
    "Signed on the day": "दिन पर हस्ताक्षर किए",
    "Sign in daily to receive rupees": "रुपये प्राप्त करने के लिए प्रतिदिन साइन इन करें",
    "Daily check-in to earn Rs.": "रुपये कमाने के लिए दैनिक चेक-इन।",
    "You have check-in for ": "आपके पास लगातार ",
    " consecutive days": " दिनों के लिए चेक-इन है",

    //My Funds
    "My Funds": "माई फंड्स",
    "View Details": "विवरण देखें",
    "amount": "राशि",
    "Quantity available": "उपलब्ध मात्रा",
    "Details": "विवरण",
};
