<template>
  <Header :title="$t('Team Details')"></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="table-responsive">
        <div class="search">
          <div class="form-group boxed">
              <div class="input-wrapper">
                  <select class="form-control custom-select" v-model="level">
                      <option value="">All</option>
                      <option value="1">Level A</option>
                      <option value="2">Level B</option>
                      <option value="3">Level C</option>
                  </select>
              </div>
          </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{$t("Recharge reward")}}</th>
                    <th scope="col">{{$t("Commissions")}}</th>
                    <th scope="col">{{$t("Commissions Total")}}</th>
                </tr>
                <tr>
                    <th scope="col">{{$t("Total")}}</th>
                    <th scope="col">{{root.recharge_reward}}</th>
                    <th scope="col">{{root.buyfund_commissions}}</th>
                    <th scope="col">{{root.total_commissions}}</th>
                </tr>
                <tr>
                    <th scope="col">{{$t("My Team")}}</th>
                    <th scope="col">level</th>
                    <th scope="col">{{$t("Phone")}}</th>
                    <th scope="col">{{$t("Investment amount")}}</th>
                </tr>
            </thead>
            
            <tbody>
              
                <tr>
                  <td colspan="4" v-if="team_list.length === 0">
                    <div class="mt-1">{{ $t("no more data") }}</div>
                  </td>
                </tr>
                <tr v-for="(cy, index) in team_list" v-bind:key="index">
                    <th scope="row">{{index + 1}}</th>
                    <td>
                      <div class="chip chip-info chip-media level-a" v-if="cy.distance === 1">
                          <i class="chip-icon bg-primary level-chip-i">
                              <span>level A</span>
                          </i>
                          <span class="chip-label"></span>
                      </div>

                      <div class="chip chip-primary chip-media level-b" v-if="cy.distance === 2">
                          <i class="chip-icon bg-success level-chip-i">
                              <span>level B</span>
                          </i>
                          <span class="chip-label"></span>
                      </div>

                      <div class="chip chip-media chip-danger level-c" v-if="cy.distance === 3">
                          <i class="chip-icon bg-warning level-chip-i">
                              <span>level C</span>
                          </i>
                          <span class="chip-label"></span>
                      </div>
                    </td>
                    <td><span>{{cy.phone}}</span></td>
                    <td><span>{{cy.buyfund_amount}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import { getmyteam } from "../common/api";
import i18n from '../lang/index';
export default {
  name: "TeamDetail",
  components: {
    Header,
  },
  data() {
    return {
      team_list: [],
      copy_team_list: [],
      root: {},
      level: "",
    }
  },
  watch: {
    level(newval) {
      if(newval === "") {
        this.team_list = this.copy_team_list;
        return;
      } 
      let new_team_list = []
      for(let child of this.copy_team_list) {
        console.log(child);
        if(String(child.distance) == String(newval)) {
          new_team_list.push(child);
        }
      }
      this.team_list = new_team_list;
    },
  },
  methods: {
     treeToList(
      tree = [],
      idValue = null,
      childrenField = 'children',
      idField = 'id',
      parentIdField = 'pid',
    ) {
      const list = []
      if (!childrenField) childrenField = 'children'
      for (let i = 0, j = tree.length; i < j; i++) {
        const d = tree[i]
        const id = d[idField]
        if (!list.some((l) => l[idField] === id)) {
          list.push(d)
        }
        if (parentIdField) d[parentIdField] = idValue
        const children = d[childrenField]
        if (children && children.length > 0) {
          const items = treeToList(
            children,
            id,
            childrenField,
            idField,
            parentIdField,
          )
          const values = items.values()
          for (const v of values) {
            if (!list.some((l) => l[idField] === v[idField])) {
              list.push(v)
            }
          }
        }
      }
      return list
    },
  },
  mounted() {
    loading(i18n.global.t("Loading..."));
    getmyteam({}).then((res)=>{
      if(res.code === 200) {
        this.team_list = this.treeToList(res.data.childs);
        this.copy_team_list = this.team_list;
        this.root = res.data.root;
      }
      loading_close();
    })
  },
  
};
</script>

<style scoped>
.teamdetail {
    color: #1E74FD;
}
.level-chip-i {
   width: 49px;
}
.chip.chip-media {
    position: relative;
    padding-left: 0px;
}
.table td, .table th {
    padding: 11px 0px 9px 1px;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.level-a {
  margin-left: -55px;
}
.level-b {
  margin-left: -49px;
}
.level-c {
  margin-left: -50px;
}
</style>