<template>
  <Header></Header>
  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="login-form">
      <div class="section">
        <h1>{{ $t("Change Password") }}</h1>
      </div>
      <div class="section mt-2 mb-5">
        <form>
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                v-model="original_password"
                placeholder="Original Password"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                v-model="new_password"
                placeholder="New password"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                v-model="new_password2"
                placeholder="Repeat New Password"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-button-group">
            <button
              type="button"
              class="btn btn-primary btn-block btn-lg"
              @click="handleChangepassword"
            >
              {{ $t("Confirm modification") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- * App Capsule -->

  <SuccessErr></SuccessErr>
</template>

<script>
// @ is an alias to /src
import i18n from "../lang/index";
import Header from "../components/Header.vue";
import SuccessErr from "../components/SuccessErr.vue";
import { changepassword } from "../common/api";
export default {
  name: "ChangePassword",
  components: {
    Header,
    SuccessErr,
  },
  data() {
    return {
      original_password: "",
      new_password: "",
      successmsg: i18n.global.t("success"),
      errmsg: "",
    };
  },
  methods: {
    handleChangepassword() {
      if (this.original_password.length < 6) {
        toastbox("error", 2000, i18n.global.t("Please enter the correct format password"));
        return;
      }
      if (this.new_password.length < 6) {
        toastbox("error", 2000, i18n.global.t("Please enter the correct format password"));
        return;
      }
      if (this.new_password != this.new_password2) {
        toastbox("error", 2000, i18n.global.t("The passwords entered twice do not match"));
        return;
      }
      changepassword({
        original_password: this.original_password,
        new_password: this.new_password,
      }).then((res) => {
        if (res.code === 200) {
          toastbox("success", 2000, i18n.global.t("Successfully modified"));
          this.$router.push({ name: "my" });
        } else {
          toastbox("error", 2000, res.errmsg);
          return;
        }
      });
    },
  },
};
</script>
