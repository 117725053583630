export default {
    "$": "₹",

    //home
    "Company": "Company",
    "My Team": "My Team",
    "Recharge": "Recharge",
    "Recharge details": "Recharge details",
    "Fund": "Fund",
    "Hot fund": "Hot fund",
    "Contact": "Contact",
    "Insights": "Insights",
    "Market Insights": "Market Insights",
    "The ‘Great Disconnect’: Can investors find opportunity in disagreement?": "The ‘Great Disconnect’: Can investors find opportunity in disagreement?",
    "Read More": "Read More",
    "About us": "About us",
    "New Release Notice": "New Release Notice",
    "A new version is detected, please update now": "A new version is detected, please update now",
    "Update Later": "Update Later",
    "Update Now": "Update Now",

    //news
    "Detail": "Detail",
    "NEWS": "NEWS",
    "News": "News",

    //fund
    "Insufficient balance": "Insufficient balance",
    "Please log in to invest": "Please log in to invest",
    "Today's earnings": "Today's earnings",
    "Buy": "Buy",
    "Minimum investment amount": "Minimum investment amount",
    "Investment amount": "Investment amount",
    "No data": "No data",
    "Total investment": "Total investment",
    "Total interest": "Total interest",
    "Today's interest": "Today's interest",
    "Total Copies": "Total copies",
    "Frequency": "Frequency",
    "Exceeded maximum frequency": "Exceeded maximum frequency",
    "Quantity": "Quantity",
    "Start time": "Start time",
    "Expire date": "Expire date",
    "Date": "Date",
    "Income": "Income",
    "View details": "View details",
    "Fund info": "Fund Info",
    "Interest Details": "Interest Details",
    "Introduction to the Fund": "Introduction to the Fund",
    "Quota": "Quota",
    "Invitation bonus fund": "Invitation bonus fund",
    "Reward History": "Reward History",
    "No reward yet, invite friends to invest together to get Invitation bonus fund": "No reward yet, invite friends to invest together to get Invitation bonus fund",
    "Previous": "Previous",
    "Next": "Next",
    "Daily rate of return": "Daily rate of return",
    "Total revenue": "Total revenue",
    "Get lucky earnings": "Get lucky earnings",



    //fund detail
    "Fund detail": "Fund Detail",
    "Daily income": "Daily income",
    "days": "days",
    "Cycle": "Cycle",
    
    //my
    "My": "My",
    "Email": "Email",
    "Amount": "Amount",
    "Add value today": "Add value today",
    "Recharge": "Recharge",
    "Withdraw": "Withdraw",
    "Capital details": "Capital Details",
    "Withdraw details": "Withdraw Details",
    "Purchase History": "Purchase History",
    "Sign Out": "Sign Out",
    "Confirm to log out": "Confirm to log out",
    "Confirm to log out of the current account": "Confirm to log out of the current account",
    "CLOSE": "CLOSE",
    "OK": "OK",
    "value added treasure": "value added treasure",
    "Balance": "Balance",
    "Assets": "Assets",
    "Transfer in": "Transfer in",
    "Transfer amount": "Transfer amount",
    "Register time": "Register time",
    "Notice": "Notice",
    "Holding fund": "Holding fund",

    //recharge
    "Recharge Details": "Recharge Details",
    "Recharge amount": "Recharge Amount",
    "The recharge amount cannot be 0": "The recharge amount cannot be 0",
    "Successfully Recharge": "Successfully Recharge",
    "Recharge failed": "Recharge failed",
    "Recharging in progress": "Recharging in progress",
    "Recharge Again": "Recharge Again",
    "Payment Tutorial": "Payment Tutorial",
    "Recharge Tutorial": "Recharge Tutorial",
    "please enter the amount to recharge": "please enter the amount to recharge",
    "Payment method": "Payment method",

    //rechargeListBox
    "All": "All",
    "Recharge": "Recharge",
    "Withdraw": "Withdrawal",
    "Lucky Earnings": "Lucky Earnings",
    "Interest": "Interest",
    "Reward": "Reward",
    "Investment": "Investment",
    "Principal Amount": "Principal Amount",
    "Commission": "Commission",
    "Treasure Deposit": "Treasure Deposit",
    "Treasure Takeout": "Treasure Takeout",
    "Withdrawal Return": "Withdrawal Return",
    

    //withdraw
    "Withdraw amount": "Withdraw Amount",
    "Successfully submitted application, please wait for review": "Successfully submitted application, please wait for review",
    "Withdrawal failed": "Withdrawal failed",
    "Withdrawal information": "Withdrawal information",
    "Add": "Add",
    "Edit": "Edit",
    "Please add withdrawal information first": "Please add withdrawal information first",
    "Name": "Name",
    "Bank Numbers": "Bank Numbers",
    "Done": "Done",
    "Bank Name": "Bank Name",
    "Please enter a name": "Please enter a name",
    "Please enter bank card number": "Please enter bank card number",
    "Please enter the bank": "Please enter the bank",
    "Successfully": "Successfully",
    "IFSC": "IFSC",
    "Invalid IFSC": "Invalid IFSC",
    "IFSC Point": "Please enter 11 correct IFSC",
    "Withdrawal time: Every week: Monday, Tuesday, Wednesday, Thursday, Friday at 9:00 am to 6:00 PM": "Withdrawal time: Every week: Monday, Tuesday, Wednesday, Thursday, Friday at 9:00 am to 6:00 PM",
    "Tax": "Tax",
    "Application Amount": "Application Amount",
    "Reason": "Reason",
    "Please enter withdraw amount": "Please enter withdraw amount",

    //login
    "Fill the form to log in": "Fill the form to log in",
    "Email address": "Email address",
    "Password": "Password",
    "Register Now": "Register Now",
    "Forgot Password?": "Forgot Password?",
    "Log in": "Log in",
    "CLOSE": "CLOSE",
    "Please enter password": "Please enter password",
    "please input your email": "please input your email",
    "Successful login": "Successful login",
    "Download mobile app login": "Download mobile app login",
    
    //forgotpassword
    "Login": "Login",
    "Reset Password": "Reset Password",
    "Receive verification code to change password": "Receive verification code to change password",
    "Reset": "Reset",
    "Verify identidy": "Verify identidy",
    "Initial amount": "Initial amount",
    "progress": "progress",
    "Password reset successfully, please log in again": "Password reset successfully, please log in again",
    "Authentication passed": "Authentication passed",

    //change password
    "Please enter the correct format password": "Please enter the correct format password",
    "success": "success",
    "OK": "OK",
    "Change Password": "Change Password",
    "Successfully modified": "Successfully modified",
    "Confirm modification": "Confirm modification",
    "The passwords entered twice do not match": "The passwords entered twice do not match",

    //Team
    "My invitation": "My invitation",
    "Copy": "Copy",
    "Copy successfully": "Copy successfully",
    "Copy failed": "Copy failed",
    "Full details": "Full details",
    "Today's details": "Today's details",
    "Team": "Team",
    "My Team": "My Team",
    "Commissions": "Commissions",
    "Commission details": "Commission details",
    "Commissions Total": "Commissions Total",
    "Commission Today": "Commission Today",
    "Commission Transferable": "Commission Transferable",
    "Transfer out": "Transfer out",
    "Team Details": "Team Details",
    "Buying fund staff": "Buying fund staff",
    "Member purchase amount": "Member purchase amount",
    "Insufficient commission": "Insufficient commission",
    "Recharge reward": "Recharge reward",
    "Commission": "Commission",
    "Total": "Total",
    "When your lower-level members buy funds, you can get 7% commission of the purchase amount for level-A members, 3% for level-B members, and 2% for level-C members": "When your lower-level members buy funds, you can get 7% commission of the purchase amount for level-A members, 3% for level-B members, and 2% for level-C members",
    "When your A-level member successfully recharges for the first time, you can get a 5% commission on the recharge amount": "When your level-A member successfully recharges for the first time, you can get a 5% commission on the recharge amount",
    
    //register
    "Send SMS": "Send SMS",
    "Register": "Register",
    "Fill the form to join us": "Fill the form to join us",
    "Username": "Username",
    "Full name": "Full mame",
    "Please fill in a valid username": "Please fill in a valid username",
    "Phone": "Phone",
    "Captcha": "Captcha",
    "Password": "Password",
    "Password (again)": "Password (again)",
    "Inviter code": "Inviter code",
    "Please type in your name": "Please type in your name",
    "Please enter password": "Please enter password",
    "Please enter a password of at least 6 characters, including at least 1 uppercase letter and 1 lowercase letter to ensure the security of your account": "Please enter a password of at least 6 characters, including at least 1 uppercase letter and 1 lowercase letter to ensure the security of your account",
    "The passwords entered twice do not match": "The passwords entered twice do not match",
    "please enter a valid phone number": "please enter a valid phone number",
    "please enter verification code": "please enter verification code",
    "Sent successfully": "Sent successfully",
    "Resend": "Resend",
    "Send SMS": "Send SMS",
    "Order Number": "Order Number",
    "Go Back": "Go Back",
    "The registration is successful, please download the app to log in": "The registration is successful, please download the app to log in",

    // loading
    "Logging in": "Logging in",
    "In process": "In process",
    "Registration in progress": "Registration in progress",
    "Recharging in progress": "Recharging in progress",
    "Withdrawal in progress": "Withdrawal in progress",
    "Loading...": "Loading...",
    "no more data": "no more data",

    //successful registration page
    "successful registration": "successful registration",
    "Thank you for your registration, you can log in to your account, or download the APP.": "Thank you for your registration, you can log in to your account, or download the APP.",
    "Go Login": "Go Login",
    "Download App": "Download App",
    "success": "Success",


    //check-in calendar
    "Check In": "Check In",
    "signed in today": "Signed in today",
    "Check in succeeded": "Check in succeeded",
    "Signed on the day": "Signed on the day",
    "Sign in daily to receive rupees": "Sign in daily to receive rupees",
    "Daily check-in to earn Rs.": "Daily check-in to earn Rs.",
    "You have check-in for ": "You have check-in for ",
    " consecutive days": " consecutive days",
};
