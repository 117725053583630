export default {
    "$": "₹",

    //home
    "Company": "Компания",
    "My Team": "Моя команда",
    "Recharge": "Перезарядка",
    "Recharge details": "Детали пополнения",
    "Fund": "Фонд",
    "Hot fund": "Горячий фонд",
    "Contact": "Контакт",
    "Insights": "Взгляды",
    "Market Insights": "Аналитика рынка",
    "The ‘Great Disconnect’: Can investors find opportunity in disagreement?": "Великое разъединение»: могут ли инвесторы найти возможности в разногласиях?",
    "Read More": "Подробнее",
    "About us": "О нас",

    //news
    "Detail": "Подробно",
    "NEWS": "НОВОСТИ",
    "News": "Новости",

    //fund
    "Insufficient balance": "Недостаточный баланс",
    "Please log in to invest": "Пожалуйста, войдите, чтобы инвестировать",
    "Today's carnings": "Сегодняшние гвозди",
    "Buy": "Купить",
    "Minimum investment amount": "Минимальная сумма инвестиций",
    "Investment amount": "Сумма инвестиций",
    "No data": "Нет данных",
    "Total investment": "Всего инвестиций",
    "Total interest": "общий интерес",
    "Today's interest": "Интерес сегодня",
    "Total Copies": "Всего копий",
    "Frequency": "Частота",
    "Exceeded maximum frequency": "Превышена максимальная частота",
    "Quantity": "количество",
    "Start time": "Время начала",
    "Expire date": "Дата истечения срока действия",
    "Date": "Свидание",
    "Income": "Доход",
    "View details": "View details",
    "Fund info": "Информация о фонде",
    "Interest Details": "Детали интереса",

    //fund detail
    "Fund detail": "Сведения о фонде",
    "Daily income": "Ежедневный доход",
    "days": "дни",
    "Cycle": "Цикл",

    //my
    "My": "Мой",
    "Email": "Эл. адрес",
    "Amount": "количество",
    "Add value today": "Добавьте ценность сегодня",
    "Recharge": "Перезарядка",
    "Withdraw": "Снятие",
    "Capital details": "Капитальные детали",
    "Withdraw details": "Детали вывода",
    "Purchase History": "История на покупките",
    "Sign Out": "Выход",
    "Confirm to log out": "Подтвердите выход",
    "Confirm to log out of the current account": "Подтвердите выход из текущей учетной записи",
    "CLOSE": "ЗАКРЫТЬ",
    "OK": "OK",
    "value added treasure": "Добавленная стоимост",
    "Balance": "Остаток средств",
    "Assets": "Активы",
    "Transfer in": "Трансфер в",
    "Transfer amount": "Сумма перевода",
    "Register time": "Время регистрации",
    "Notice": "Замечать",

    //recharge
    "Recharge Details": "Детали пополнения",
    "Recharge amount": "Сумма пополнения",
    "The recharge amount cannot be 0": "Сумма пополнения не может быть 0",
    "Successfully Recharge": "Успешно пополнить счет",
    "Recharge failed": "Ошибка перезарядки",
    "Recharging in progress": "Идет перезарядка",
    "Recharge Again": "Пополнить снова",
    "Payment Tutorial": "Учебник по оплате",

    //rechargeListBox
    "All": "Все",
    "Recharge": "Перезарядка",
    "Withdraw": "Вывод",
    "Interest": "Интерес",
    "Reward": "Награда",
    "Investment": "Инвестиции",
    "Principal Amount": "Основная сумма",
    "Commission": "Комиссия",
    "Treasure Deposit": "Депозит сокровищ",
    "Treasure Takeout": "Вынос сокровищ",
    "Withdrawal Return": "Возврат",



    //withdraw
    "Withdraw amount": "Сумма снятия",
    "Successfully submitted application, please wait for review": "Заявка успешно отправлена, пожалуйста, дождитесь рассмотрения",
    "Withdrawal failed": "Снятие средств не удалось",
    "Withdrawal information": "Информация o выводе средств",
    "Add": "Добавлять",
    "Edit": "Edit",
    "Please add withdrawal information first": "Пожалуйста, сначала добавьте информацию о снятии средств",
    "Name": "Имя",
    "Bank Account": "Банковский счет",
    "Done": "Сделанный",
    "Bank": "Банк",
    "Please enter a name": "Пожалуйста, введите имя",
    "Please enter bank card number": "Пожалуйста, введите номер банковской карты",
    "Please enter the bank": "Пожалуйста, войдите в банк",
    "Successfully": "Успешно",
    "IFSC": "IFSC",
    "Invalid IFSC": "Недействительный IFSC",
    "IFSC Point": "Пожалуйста, введите 11 правильных IFSC",
    "Withdrawal time: Every week: Monday, Tuesday, Wednesday, Wednesday, Thursday, Friday at 9:00 am to 6:00 PM": "Время вывода: каждую неделю: понедельник, вторник, среда, среда, четверг, пятницу в 9:00 до 18:00.",
    "Tax": "Tax",
    //login
    "Fill the form to log in": "Заполните форму, чтобы войти",
    "Email address": "Адрес электронной почты",
    "Password": "Пароль",
    "Register Now": "Зарегистрируйтесь сейчас",
    "Forgot Password?": "Забыл пароль?",
    "Log in": "Авторизоваться",
    "CLOSE": "ЗАКРЫТЬ",
    "Please enter password": "Пожалуйста введите пароль",
    "please input your email": "Пожалуйста, введите свой адрес электронной почты",
    "Successful login": "успешный вход в систему",
    "Download mobile app login": "Скачать вход в мобильное приложение",

    //forgotpassword
    "Login": "Авторизоваться",
    "Reset Password": "Сброс пароля",
    "Receive verification code to change password": "Получите код подтверждения для смены пароля",
    "Reset": "Перезагрузить",
    "Verify identidy": "Подтвердить личность",
    "Initial amount": "начальная сумма",
    "progress": "прогресс",
    "Password reset successfully, please log in again": "Пароль успешно сброшен, пожалуйста, войдите снова",
    "Authentication passed": "Аутентификация пройдена",

    //change password
    "Please enter the correct format password": "Пожалуйста, введите пароль в правильном формате",
    "success": "успех",
    "OK": "ХОРОШО",
    "Change Password": "Изменить пароль",
    "Successfully modified": "Успешно изменено",
    "Confirm modification": "Подтвердить изменение",
    "The passwords entered twice do not match": "Введенные дважды пароли не совпадают",

    //Team
    "My invitation": "Мое приглашение",
    "Copy": "Копировать",
    "Copy successfully": "Копировать успешно",
    "Copy failed": "Не удалось скопировать",
    "Full details": "Полная информация",
    "Today's details": "Сегодняшние подробности",
    "Team": "Команда",
    "My Team": "Моя команда",
    "Commission details": "Детали комиссии",
    "Commissions Total": "Всего комиссий",
    "Commission Today": "Комиссия сегодня",
    "Commission Transferable": "Комиссия",
    "Transfer out": "Трансфер из",
    "Team Details": "Детали команды",
    "Buying fund staff": "Покупка персонала фонда",
    "Member purchase amount": "Сумма покупки участника",
    "Insufficient commission": "Недостаточная комиссия",
    "Recharge reward": "Пополнить награду",
    "Commission": "комиссия",
    "Total": "Всего",
    "When your lower-level members buy funds, you can get 7% commission of the purchase amount for level-A members, 3% for level-B members, and 2% for level-C members": "Когда ваши участники более низкого уровня покупают средства, вы можете получить комиссию 7% от суммы покупки для участников уровня A, 3% для участников уровня B и 2% для участников уровня C.",
    "When your level-A member successfully recharges for the first time, you can get a 5% commission on the recharge amount": "Когда ваш участник A-level успешно пополняет счет в первый раз, вы можете получить комиссию в размере 5% от суммы пополнения.",

    //register
    "Send SMS": "Отправить смс",
    "Register": "регистр",
    "Fill the form to join us": "Заполните форму, чтобы присоединиться к нам",
    "Username": "Имя пользователя",
    "Full name": "ФИО",
    "Please fill in a valid username": "Пожалуйста, введите действительное имя пользователя",
    "Phone": "Телефон",
    "Captcha": "Капча",
    "Password": "Пароль",
    "Password (again)": "Пароль снова)",
    "Inviter code": "Код приглашения",
    "Please type in your name": "Пожалуйста, введите ваше имя",
    "Please enter password": "Пожалуйста введите пароль",
    "The passwords entered twice do not match": "Введенные дважды пароли не совпадают",
    "Please enter a password of at least 6 characters, including at least 1 uppercase letter and 1 lowercase letter to ensure the security of your account": "Пожалуйста, введите пароль длиной не менее 6 символов, включая как минимум 1 прописную букву и 1 строчную букву, чтобы обеспечить безопасность вашей учетной записи.",
    "please enter a valid phone number": "Пожалуйста, введите действующий телефонный номер",
    "please enter verification code": "пожалуйста, введите проверочный код",
    "Sent successfully": "Успешно отправлено",
    "Resend": "Отправить",
    "Send SMS": "Отправить смс",
    "Order Number": "Номер заказа",
    "Go Back": "Вернись",
    "The registration is successful, please download the app to log in": "Регистрация прошла успешно, пожалуйста, скачайте приложение для входа",

    // loading
    "Logging in": "Вход в систему",
    "In process": "B процессе",
    "Registration in progress": "Идет регистрация",
    "Recharging in progress": "Идет перезарядка",
    "Withdrawal in progress": "Снятие средств в процессе",
    "Loading...": "Loading...",
    "no more data": "больше нет данных",


    //successful registration page
    "successful registration": "успешная регистрация",
    "Thank you for your registration, you can log in to your account, or download the APP.": "Спасибо за регистрацию, вы можете войти в свою учетную запись или загрузить приложение.",
    "Go Login": "Перейти Войти",
    "Download App": "Скачать приложение",
    "success": "успех",

    //check-in calendar
    "Check In": "Регистрироваться",
    "signed in today": "Signed in today",
    "Check in succeeded": "Выполнил вход сегодня",
    "Signed on the day": "Подписано в день",
    "Sign in daily to receive rupees": "Заходите ежедневно, чтобы получать рупии",
    "Daily check-in to earn Rs.": "Ежедневный вход для получения Rs.",
    "You have check-in for ": "У вас есть регистрация в течение ",
    " consecutive days": " дней подряд",
};
