<template>
  <Header :title="$t('Recharge')" goroutername="my"></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="section mt-3 mb-3">
      <div class="card">
        <div class="card-body justify-content-between align-items-end">
          <div>
            <h6 class="card-subtitle">{{$t('Balance')}}: </h6>
            <h5 class="card-title mb-0 d-flex align-items-center justify-content-between">
              {{$t("$")}}{{  $store.state.investorinfo.balance }}
            </h5>
          </div>

          <div>
            <div class="text-left mt-3">
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="rechargelabel" for="amount">{{$t('Recharge amount')}}</label>
                        <input type="number" class="form-control" v-model="recharge_amount"
                            :placeholder="$t('please enter the amount to recharge')">
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                </div>
            </div>
          </div>
          <div class="section-title">{{$t('Payment method')}}</div>
            <div class="mt-2">
                <div class="custom-control custom-radio mb-2" v-for="way in the_ways" v-bind:key="way.id">
                    <input type="radio" :id="'the_way' + way.id" :value="way.id" class="custom-control-input" v-model="the_way">
                    <label class="custom-control-label" :for="'the_way' + way.id">
                      <img :src="$store.state.siteconfig.images_host + way.img" class="payimg"/>
                      <span class="payname">{{way.name}}</span>
                    </label>
                    <span class="payment_tutorial" v-if="way.relate_newid !== 0" @click="gopaymenttutorial(way.relate_newid)">{{$t("Recharge Tutorial")}}</span>
                </div>
            </div>

          <button id="recharge" type="button" class="btn btn-primary btn-lg btn-block mt-2" @click="handlerecharge">{{$t('Recharge')}}</button>
          <span class="notesa">Reminder: Please get the latest account payment.</span>
        </div>
      </div>
    </div>
  </div>
  <BottomMenu></BottomMenu>
  <SuccessErr></SuccessErr>

</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BottomMenu from "../components/BottomMenu.vue";
import SuccessErr from "../components/SuccessErr.vue";
import i18n from '../lang/index';
import { recharge, getpaychannel, gopayapi } from "../common/api";
export default {
  name: "Recharge",
  data() {
    return {
      recharge_amount: "",
      the_ways: "",
      the_way: "",
      orderno: "",
    };
  },
  components: {
    Header,
    BottomMenu,
    SuccessErr
  },
  created() {
    this.$store.dispatch('asyncupdateinvestorinfo');
    this.$store.dispatch('asyncupdatesiteconfig');
    getpaychannel({}).then((res)=>{
      if(res.code==200) {
        this.the_ways = res.data;
      }
    });
  },
  mounted() {},
  methods: {
    gopaymenttutorial(relate_newid) {
      this.$router.push("/newsdetail/" + relate_newid);
    },
    handlerecharge() {
      if(Number(this.recharge_amount) <= 0) {
        toastbox("error", 2000, i18n.global.t('The recharge amount cannot be 0'));
        return;
      }
      loading(i18n.global.t("Recharging in progress"));
      recharge({
        amount: this.recharge_amount,
        the_way: this.the_way
      }).then((res)=>{
        if(res.code === 200) {
          //toastbox("success", 2000, i18n.global.t('Successfully Recharge'));
          this.orderno = res.data.orderno;
          this.gopay();
        } else {
          loading_close();
          toastbox("error", 2000, res.errmsg);
        }
      });
    },
    gopay() {
      gopayapi({
        recharge_orderno: this.orderno
      }).then((res)=>{
        if(res.code === 200) {
          if(process.env.NODE_ENV === "app") {
            this.$router.push("/rechargeresult/" + this.orderno);
            window.location.href = "xamlp:" + res.data.gopay_url;
            // cordova.InAppBrowser.open(res.data.gopay_url, '_self', 'location=yes,hardwareback=yes,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no');
          } else {
            window.location.href = res.data.gopay_url;
          }
          loading_close();
          return false;
        } else {
          loading_close();
          toastbox("error", 2000, res.errmsg);
        }
      })
    }
  },
};
</script>

<style scoped>
.card .card-body {
    padding: 24px 16px;
    line-height: 1.4em;
    text-align: left;
}
.payimg {
  width: 64px;
  height: 23px;
  margin-right: 12px;
}
.payment_tutorial {
  position: absolute;
  right: 11px;
  top: 3px;
  font-size: 0.9rem;
  color: #1E74FD;
}
.rechargelabel {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #42fc13;
  display: block;
  line-height: 0.2em;
  margin-bottom: 22px;
}
.notesa {
  display: block;
  font-weight: 500;
  font-size: 0.9rem;
  color: red;
  margin-top: 12px;
}
</style>
