<template>
    <Header :title="$t('My')" :showleft="false"></Header>
    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="section mt-2">
            <div class="profile-head">
                <div class="avatar">
                    <img src="/assets/img/sample/avatar/avatar1.jpg" alt="avatar" class="imaged w64 rounded">
                </div>
                <div class="in">
                    <h3 class="name" style="text-align: left;">ID: {{ $store.state.investorinfo.name }}</h3>
                    <h5 class="subtext" style="text-align: left;">
                        {{ $t("Phone") }}: {{ phone }}
                        <ion-icon name="eye-outline" v-if="phone_show" @click="showphone()"></ion-icon>
                        <ion-icon name="eye-off-outline" v-if="!phone_show" @click="showphone()"></ion-icon>
                    </h5>
                </div>
            </div>
        </div>
        <div class="section full mt-2">
            <div class="row">
                <div class="col-6">
                    <div class="card product-card">
                        <div class="card-body">
                            <h2 class="balance_assets_title">{{ $t("Balance") }}</h2>
                            <div class="price">{{ $t('$') }} {{ $store.state.investorinfo.balance }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card product-card">
                        <div class="card-body">
                            <h2 class="balance_assets_title" @click="handleassetsdropdown">{{ $t("Assets") }}</h2>
                            <div class="price">{{ $t('$') }} {{ $store.state.investorinfo.total_assets }}</div>
                        </div>
                    </div>
                    <div class="dropdown-menu" id="assetsdropdown">
                        <a class="dropdown-item" href="#">{{ $t("Balance") }}: {{$t("$")}}{{ $store.state.investorinfo.balance }}</a>
                        <a class="dropdown-item" href="#">{{ $t("value added treasure") }}: {{ $t('$') }} {{ $store.state.investorinfo.valueadded_treasure_balance }}</a>
                        <a class="dropdown-item" href="#">{{ $t("Commissions") }}: {{$t("$")}}{{ $store.state.investorinfo.commission }}</a>
                        <a class="dropdown-item" href="#">{{ $t("Holding fund") }}: {{$t("$")}}{{ $store.state.investorinfo.principal_amount }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="section full mt-2">
            <div class="row mt-1">
                <div class="col-6">
                    <router-link class="btn btn-sm btn-success btn-block" to="/recharge">{{ $t("Recharge") }}
                    </router-link>
                </div>
                <div class="col-6">
                    <router-link class="btn btn-sm btn-warning btn-block" to="/withdraw">{{ $t("Withdraw") }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="card text-white bg-info mt-2">
            <div class="card-header">
                <span>{{ $t("value added treasure") }}</span>
                <span class="valueadddetailsviewdetail" @click="$router.push('/valueadddetails')">{{$t("View Details")}}</span>
            </div>
            <div class="card-body">
                <div v-html="addedtreasuredesc" v-if="addedtreasuredesc!==''">
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="card product-card bg-primary">
                            <div class="card-body">
                                <h2 class="title text-white">{{ $t("Amount") }}</h2>
                                <div class="price text-white">{{ $t('$') }} {{ $store.state.investorinfo.valueadded_treasure_balance }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card product-card bg-primary">
                            <div class="card-body">
                                <h2 class="title text-white">{{ $t("Add value today") }}</h2>
                                <div class="price text-white">{{ $t('$') }} {{ $store.state.investorinfo.today_value_added_treasure }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-6">
                        <button type="button" class="btn btn-sm btn-success btn-block" @click="showvat('in')">{{ $t("Transfer in") }}</button>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-sm btn-warning btn-block" @click="showvat('out')">{{ $t("Transfer out") }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="section mt-1 mb-2">
            <ul class="listview image-listview">
                <li>
                    <a href="javascript:;" class="item" @click="checkinget" onclick="$('#android-add-to-home-screen').modal();">
                        <div class="icon-box bg-primary">
                            <ion-icon name="calendar-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Check In") }}</div>
                        </div>
                    </a>
                    <!-- Android Add to Home Action Sheet -->
                    <div class="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content signBox">
                                <div class="tips">
                                    <span>{{ $t("You have check-in for ") }}</span><span>{{consecutive_checkins}}</span><span>{{ $t(" consecutive days") }}</span>
                                </div>
                                <a href="javascript:;" class="close-button" data-dismiss="modal">
                                    <ion-icon name="close"></ion-icon>
                                </a>
                                <div class="modal-body">
                                    <p class="history">{{ $t("Daily check-in to earn Rs.") }}</p>
                                    <div class="row mb-1 monthbtn">
                                        <div class="left" @click = "lastmonth"><ion-icon name="chevron-back-outline"></ion-icon></div>
                                        <div class="monthtxt">{{ formatmonth() }}</div>
                                        <div class="right" @click = "nextmonth"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                                    </div>
                                    <div class="clear"></div>
                                    <ul class="signBtnBox">
                                        <li v-for="(value, day, index) in checkin_log" :key="index">
                                            <div v-if="Number(value) != 0 && !isthismonth(day)" style=" width: 100%; height: 100%; border-radius: 4px; background-color:rgb(225 187 135);">
                                                <img src="/assets/img/Ok.png" alt="image" class="imaged w24">
                                                <p>{{ value }}</p>
                                            </div>
                                            <div v-else-if="Number(value) != 0" style=" width: 100%; height: 100%; border-radius: 4px; background-color: #ff9400; ">
                                                <img src="/assets/img/Ok.png" alt="image" class="imaged w24">
                                                <p>{{ value }}</p>
                                            </div>
                                            <div v-else-if="!isthismonth(day)" class="signBoxnew">
                                                <img src="/assets/img/lubi.png" alt="image" class="imaged w24">
                                                <p>{{ day.substr(day.indexOf("-")+1, day.length) }}</p>
                                            </div>
                                            <div v-else>
                                                <img src="/assets/img/lubi.png" alt="image" class="imaged w24">
                                                <p>{{ day.substr(day.indexOf("-")+1, day.length) }}</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="action-sheet-content text-center">
                                        <div v-if="todaycheck">
                                            <button type="button" class="btn btn-sm btn-warning btn-block disabled">{{ $t("signed in today") }}</button>
                                        </div>
                                        <div v-else>
                                            <button type="button" @click="checkin" class="btn btn-sm btn-warning btn-block">{{ $t("Check In") }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <router-link to="/capitaldetails" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="copy-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Capital details") }}</div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/rechargedetails" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="duplicate-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Recharge details") }}</div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/withdrawdetails" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="create-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Withdraw details") }}</div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/transactiondetails" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="podium-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Purchase History") }}</div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/changepassword" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="crop-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Change Password") }}</div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <a class="item" @click="handledownloadapp">
                        <div class="icon-box bg-primary">
                            <ion-icon name="logo-apple-appstore"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Download App") }}</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="item" @click="handleissignout">
                        <div class="icon-box bg-primary">
                            <ion-icon name="log-out-outline"></ion-icon>
                        </div>
                        <div class="in">
                            <div>{{ $t("Sign Out") }}</div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Dialog Basic -->
        <div class="modal fade dialogbox" id="signout" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("Confirm to log out") }}</h5>
                    </div>
                    <div class="modal-body">
                        {{ $t("Confirm to log out of the current account") }}
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn btn-text-secondary" data-dismiss="modal">{{ $t("CLOSE") }}</a>
                            <a href="#" class="btn btn-text-primary" data-dismiss="modal" @click="signout">{{ $t("OK") }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Dialog Basic -->
        <ValueAddedTreaSure :inorout="inorout"></ValueAddedTreaSure>
    </div>
    <BottomMenu></BottomMenu>
    <Chat></Chat>
    <Notice></Notice>
</template>

<script>
// @ is an alias to /src
import i18n from "../lang/index";
import Header from "../components/Header.vue";
import BottomMenu from "../components/BottomMenu.vue";
import Chat from "../components/Chat.vue";
import Notice from "@/components/Notice.vue";
import ValueAddedTreaSure from "../components/ValueAddedTreaSure.vue";
import { checkinget, checkin, getnewsCodedetail } from "../common/api";
export default {
    name: "My",
    components: {
        Header,
        BottomMenu,
        ValueAddedTreaSure,
        Chat,
        Notice,
    },
    data() {
        return {
            addedtreasuredesc: "",
            checkin_log: {},
            inorout: "in",
            monthEnglish: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"],
            phone_show: true,
            curr_year_month: new Date(),
            phone: "",
            todaycheck: false,
            consecutive_checkins: 0,
            issignout: false,
            isassetsdropdown: false,
        }
    },
    created() {
        this.$store.dispatch('asyncupdatesiteconfig');
        this.$store.dispatch('asyncupdateinvestorinfo').then(() => {
            this.showphone();
        });
        getnewsCodedetail("addedtreasuredesc").then((res)=>{
            if(res.code === 200) {
                this.addedtreasuredesc = res.data.content;
            }
        });
    },
    mounted() {
        document.addEventListener('click', (e) => {
            if (e.target.className !== 'balance_assets_title') {
                $('#assetsdropdown').css("display", "none");
            }
        })
    },
    beforeDestroy() {
        window.removeEventListener('click', () => {}, true);
    },
    methods: {
        handleassetsdropdown() {
            if(this.isassetsdropdown) {
                $('#assetsdropdown').css("display", "none");
            } else {
                $('#assetsdropdown').css("display", "block");
            }
            this.isassetsdropdown = !this.isassetsdropdown;
        },
        isthismonth(str){
            var month = str.split('-')[0];
            if (month == (this.curr_year_month.getMonth() + 1)) {
                return true;
            } else {
                return false;
            }
        },
        showphone() {
            this.phone_show = !this.phone_show;
            let source_phone = this.$store.state.investorinfo.phone;
            if (!source_phone) {
                return;
            }
            if (this.phone_show) {
                this.phone = source_phone;
            } else {
                let phone_len = source_phone.length;
                this.phone = source_phone.slice(0, 3) + "****" + source_phone.slice(phone_len - 3, phone_len);
            }
        },
        checkin() {
            loading(i18n.global.t("In process"));
            checkin({}).then(res => {
                if (res.code === 200) {
                    loading_close();
                    toastbox("success", 2000, i18n.global.t("Check in succeeded"));
                    this.checkinget();
                } else {
                    loading_close();
                    toastbox("error", 2000, i18n.global.t(res.errmsg));
                }
            });
        },
        showvat(inorout) {
            this.inorout = inorout;
            $('#vat_tmodal').modal();
        },
        handleissignout() {
            $('#signout').modal();
        },
        signout() {
            localStorage.removeItem("imfpagetoken");
            this.$router.push({ name: "login" });
        },
        handledownloadapp() {
            window.location.href = this.$store.state.siteconfig.app_download_url;
            // this.$router.push({"name": "app"});
        },
        lastmonth() {
            this.curr_year_month = new Date(this.curr_year_month.getFullYear(), this.curr_year_month.getMonth() - 1, 1);
            this.checkinget();
        },
        nextmonth() {
            this.curr_year_month = new Date(this.curr_year_month.getFullYear(), this.curr_year_month.getMonth() + 1, 1);
            this.checkinget();
        },
        formatmonth() { 
            return this.monthEnglish[this.curr_year_month.getMonth()] + " " + this.curr_year_month.getFullYear();
        },
        checkinget() {
            window.history.pushState(null, null, "#");
            checkinget({
                year: this.curr_year_month.getFullYear(),
                month: this.curr_year_month.getMonth() + 1,
            }).then(res => {
                if (res.code == 200) {
                    this.checkin_log = res.data.calendar;
                    this.todaycheck = res.data.is_checkin;
                    this.consecutive_checkins = res.data.consecutive_checkins;
                }
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.modal.action-sheet {
    z-index: 1050;
}
.clear{
    clear:both;
}
.tips {
    margin-top: 6%;
    span {
        color: #ffffff;
        font-size: 16px;
        font-family: PingFang SC;
        letter-spacing: -0.1px;
        line-height: 22px;
        font-weight: 500;
    }
    :nth-of-type(2) {
        color: #fe9300;
        letter-spacing: -0.1px;
        font-size: 24px;
    }
}
.monthbtn {
    font-size: 24px;
    color: white;
    .left {
        float: left;
        margin-left: 15px;
    }
    .right {
        float: right;
        margin-right: 15px;
    }
    .monthtxt {
        margin: auto;
    }
}
.history {
    opacity: 0.5;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;
    margin: 3% 0 6% 0;
}
.valueadddetailsviewdetail {
    position: absolute;
    right: 20px;
    color: #39e063;
}
.signBoxnew {
    border-radius: 5px;
    background: #737578;
}
.signBox {
    width: 100%;
    background: #2d3949;
    .signBtnBox {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        &>li {
            list-style-type: none;
            cursor: pointer;
            width: calc((100% - 34px) / 7);
            min-width: calc((100% - 34px) / 7);
            max-width: calc((100% - 34px) / 7);
            height: 50px;
            background: #28313e;
            border-radius: 5px;
            margin: 0 5px 5px 0;
            &:nth-child(7n) {
                margin-right: 0;
            }
            img {
                margin: 3px;
            }
            p {
                text-align: center;
                opacity: 0.6;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #ffffff;
                letter-spacing: 0;
                font-weight: 500;
            }
        }

    }
}
.android-add-to-home .modal-content:before {
    border-top: 10px solid #2d3949;
}
.balance_assets_title {
    font-size: 0.9rem;
    margin: 10px 0 0 0;
    padding: 0 4px;
    font-weight: 500;
}
.bg-info {
    background: #7e50ee!important;
}
#assetsdropdown {
    left: -90px;
}
</style>