<template>
  <Header :title="$store.state.siteconfig.website_name" :showleft="false"></Header>
  
  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="section full mt-2 mb-1">
        <div class="carousel-full owl-carousel owl-theme">
            <div class="item" v-for="img in imgs" v-bind:key="img">
              <img :src="img" alt="alt" class="imaged imagedh w-100 square">
            </div>
        </div>
    </div>

    <div class="section mt-2">
        <div class="card">
            <img src="/assets/img/home/insights.jpg" class="card-img-top" alt="image">
            <div class="card-body">
                <h6 class="card-subtitle">{{$t("Insights")}}</h6>
                <h5 class="card-title">{{$t("Market Insights")}}: </h5>
                <p class="card-text">{{$t("The ‘Great Disconnect’: Can investors find opportunity in disagreement?")}}</p>
                <p class="card-text" @click="$router.push('/newsdetail/insights')"><small>{{$t("Read More")}}</small></p>
            </div>
        </div>
    </div>

    <div class="section mt-2">
            <img src="/assets/img/home/abous_us.jpg" alt="image" class="imaged square w-100">
        </div>
        <div class="section full mt-2">
            <div class="section-title">{{$t("About us")}}</div>
            <div class="wide-block pt-2 pb-1" v-html="aboutus">
                
            </div>
        </div>
  </div>
  <!-- * App Capsule -->

  <BottomMenu></BottomMenu>
  <Chat v-if="chatshow"></Chat>
  <Notice></Notice>

  <!-- Dialog Iconed Inline -->
  <div class="modal fade dialogbox" id="updatenotice" data-backdrop="static" tabindex="-1"
      role="dialog">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">{{$t("New Release Notice")}}</h5>
              </div>
              <div class="modal-body">
                  {{$t("A new version is detected, please update now")}} {{ version }} >>> {{ new_version }}
              </div>
              <div class="modal-footer">
                  <div class="btn-inline">
                      <a href="javascript:;" class="btn btn-text-danger" data-dismiss="modal">
                          <ion-icon name="disc-outline"></ion-icon>
                          {{$t("Update Later")}}
                      </a>
                      <a href="javascript:;" class="btn btn-text-primary" data-dismiss="modal" @click="updatenow">
                          <ion-icon name="download-outline"></ion-icon>
                          {{$t("Update Now")}}
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- * Dialog Iconed Inline -->
</template>


<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BottomMenu from "../components/BottomMenu.vue";
import FundList from "@/components/FundList.vue";
import Notice from "@/components/Notice.vue";
import Chat from "../components/Chat.vue";
import { VERSION } from "../common/version";
import { getnewsCodedetail, getsiteconfig} from "../common/api";
export default {
  name: "HomeView",
  components: {
      Header,
      BottomMenu,
      FundList,
      Notice,
      Chat,
  },
  data() {
    return {
      imgs: [],
      notice: {},
      aboutus: "",
      chatshow: false,
      phoneimgs: [
        "/assets/img/home/home01.png",
        "/assets/img/home/home02.png",
        "/assets/img/home/home03.png",
        "/assets/img/home/home04.jpg",
        "/assets/img/home/home05.png"
      ],
      pcimgs: [
        "/assets/img/home/homepc01.jpg",
        "/assets/img/home/homepc02.jpg",
        "/assets/img/home/homepc03.jpg",
        "/assets/img/home/homepc04.jpg",
        "/assets/img/home/homepc05.jpg"
      ],
      android_download_url: "",
      version: VERSION,
      new_version: "",
    }
  },
  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
    this.$nextTick(() => {
      $(".owl-hidden").removeClass("owl-hidden");
      $('.carousel-full').owlCarousel({
          loop: true,
          margin: 0,
          nav: false,
          items: 1,
          dots: true,
          autoplay: true,
          autoplayTimeout: 3000
      });
    });
    getnewsCodedetail("aboutus").then((res)=>{
      if(res.code === 200) {
        this.aboutus = res.data.content;
      }
    });
    if(localStorage.getItem('imfpagetoken')) {
      this.chatshow = true;
    };
  },
  mounted() {
      const that = this;  
      let height = window.innerHeight; // 屏幕高度
      let width = window.innerWidth; // 屏幕宽度 
      if(height < width) {
        this.imgs = this.pcimgs;
      } else {
        this.imgs = this.phoneimgs;
      }
      if(process.env.NODE_ENV === "app") {
        let that = this;
        $.ajax({
          type: "get",
          url: "https://version.iphostapi.workers.dev/",
              async: true,
              success: function (data) {
                if(Number(VERSION.replace(".", "")) < Number(data.android_version.replace(".", ""))) {
                  if(data.force_update) {
                    window.location.href = data.android_download_url;
                    setTimeout(function(){
                      navigator.app.exitApp();
                    }, 2000);
                  } else {
                    that.new_version = data.android_version;
                    that.android_download_url = data.android_download_url;
                    $("#updatenotice").modal();
                  }
                }
            },
            // Ajax接口出现错误时执行下述代码
            error: function(XMLHttpRequest, textStatus, errorThrown) {
              $.get('https://version.guozehui.workers.dev/', function(data) {
                  if(Number(VERSION.replace(".", "")) < Number(data.android_version.replace(".", ""))) {
                    if(data.force_update) {
                      window.location.href = data.android_download_url;
                      setTimeout(function(){
                        navigator.app.exitApp();
                      }, 2000);
                    } else {
                      that.new_version = data.android_version;
                      that.android_download_url = data.android_download_url;
                      $("#updatenotice").modal();
                    }
                  }
              });
            }
        });
      }
  },
  methods: {
    handleGoCompany() {
      this.$router.push('/newsdetail/company');
    },
    updatenow() {
      if(this.android_download_url === "") {
        if(process.env.NODE_ENV === "app") {
            window.location.href = "xamla:" + this.$store.state.siteconfig.android_download_url;
        } else {
            window.location.href = this.$store.state.siteconfig.android_download_url;
        }
      } else {
        if(process.env.NODE_ENV === "app") {
            window.location.href = "xamla:" + this.android_download_url;
        } else {
            window.location.href = this.android_download_url;
        }
      }
      setTimeout(function(){
        navigator.app.exitApp();
      }, 2000);
    }
  }
};
</script>

<style scoped>
.iconedbox {
  color: white;
  background-color: #1E74FD;
}
.menu_card {
  width: 21%;
  margin: auto;
}
.imagedh {
  height: 60%;
}
</style>