<template>
  <Header :title="$t('Fund')" :showleft="false"></Header>
  <!-- App Capsule -->
  <div id="appCapsule">
        <div class="card text-white bg-other mb-2">
            <div class="card-header">
              <div class="giftfund" @click="gorewardfund">
                <ion-icon name="gift-outline"></ion-icon>
                <span>{{$t("Invitation bonus fund")}}</span>
              </div>
              <p class="fundviewdetails" @click="viewdetail">{{$t("View Details")}}</p>
              <p class="fs-12">{{$t('Total investment')}}</p>
              <p class="fs-12">{{$t("$")}} {{$store.state.investorinfo.total_investment}}</p>
            </div>
            <div class="card-body row">
                <div class="col-4">
                  <p class="fs-12">{{$t("Total interest")}}</p>
                  <p>{{$t("$")}} {{$store.state.investorinfo.total_interest}}</p>
                </div>
                <div class="col-4">
                  <p class="fs-12">{{$t("Today's interest")}}</p>
                  <p>{{$t("$")}} {{$store.state.investorinfo.today_interest}}</p>
                </div>
                <div class="col-4">
                  <p class="fs-12">{{$t("Total Copies")}}</p>
                  <p>{{$store.state.investorinfo.total_buytimes}}</p>
                </div>
            </div>
        </div>
        <div class="listview-title mt-1">{{$t("Today's earnings")}}</div>
        <ul class="listview image-listview min-listview">
            <li v-for="wlog in withdraw_log" v-bind:key="wlog">
                <div class="item">
                    <div class="icon-box bg-success">
                        <ion-icon name="add-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div class="wphonelog">{{wlog}}</div>
                    </div>
                </div>
            </li>
        </ul>
          <!-- App FundList -->
        <FundList></FundList>
  </div>
  <BottomMenu></BottomMenu>
  <Notice></Notice>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BottomMenu from "../components/BottomMenu.vue";
import FundList from "../components/FundList.vue";
import Notice from "@/components/Notice.vue";
import i18n from '../lang/index';
import { getwithdrawlog } from "../common/api";
export default {
  name: "Fund",
  components: {
    Header,
    BottomMenu,
    FundList,
    Notice,
  },
  data() {
    return {
        withdraw_log: [],
        wlog_setinterval: "",
    }
  },
  created() {
    this.$store.dispatch('asyncupdateinvestorinfo');
  },
  mounted() {
    this.refulsh_wlog();
    this.wlog_setinterval = setInterval(this.refulsh_wlog, 3000);
  },
  unmounted() {
    clearInterval(this.wlog_setinterval);
  },
  methods: {
    gorewardfund() {
      let reward_fundid = this.$store.state.rewardfund;
      if(reward_fundid === "") {
        return;
      } else {
        this.$router.push("/rewardfunddetail/" + reward_fundid);
      }
    },
    refulsh_wlog() {
        getwithdrawlog({
            limit: 3
        }).then((res)=>{
            if(res.code === 200) {
                this.withdraw_log = res.data;
            }
        })
    },
    viewdetail() {
      this.$router.push("/MyFundsDetails")
    }
  }
};
</script>

<style scoped>
.min-listview {
  font-size: 15px;
}
.listview-title {
    color: #1E74FD;
    padding: 1px 14px;
}
.min-listview > li .item {
    min-height: 28px;
}
.min-listview > li .item .icon-box {
    min-width: 16px;
    max-width: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    font-size: 20px;
    border-radius: 400px;
    margin-right: 8px;
}
.min-listview > li .item {
    padding: 1px 11px;
}
.fs-12 {
  font-size: 18px;
}
.wphonelog {
  font-size: 0.98em;
  white-space: nowrap;
}
.fundviewdetails {
  position: absolute;
  right: 12px;
  color: #0dff3b;
}
.giftfund {
      position: absolute;
    width: 86px;
    height: 31px;
    color: #0dff3b;
    top: 13px;
}
.giftfund ion-icon {
  width: 23px;
  height: 23px;
}
.giftfund span {
    position: relative;
    top: -5px;
}
.fs-12 {
    color: aliceblue;
}
.bg-other
{
    background-color: #7e50ee;
}
</style>