<template>
  <Header :title="$t('Team')" :showleft="false"></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="card bg-block mb-2">
      <div class="section-title">{{ $t("My invitation") }}</div>
      <div class="card-body">
        <p>{{ $store.state.investorinfo.referral_link }}</p>
        <div>
          <button
            type="button"
            class="btn btn-primary copy share"
            :data-clipboard-text="$store.state.investorinfo.referral_link"
            @click="copy"
          >
            {{ $t("Copy") }}
          </button>
            <ShareNetwork
              network="whatsapp"
              :url="$store.state.investorinfo.referral_link"
              :title="share_title"
              :description="share_text"
              :quote="share_text"
              hashtags="share_title"
            >
              <a href="javascript:;" class="btn btn-icon btn-whatsapp share">
                    <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
            </ShareNetwork>
            <ShareNetwork
              network="facebook"
              :url="$store.state.investorinfo.referral_link"
              :title="share_title"
              :description="share_text"
              :quote="share_text"
              hashtags="share_title"
            >
              <a href="javascript:;" class="btn btn-icon  btn-facebook share">
                  <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </ShareNetwork>
            <!-- <ShareNetwork
              network="instagram"
              :url="$store.state.investorinfo.referral_link"
              :title="share_title"
              :description="share_text"
              :quote="share_text"
              hashtags="share_title"
            >
              <a href="javascript:;" class="btn btn-icon btn-instagram share">
                    <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </ShareNetwork> -->
            <ShareNetwork
              network="twitter"
              :url="$store.state.investorinfo.referral_link"
              :title="share_title"
              :description="share_text"
              :quote="share_text"
              hashtags="share_title"
            >
            <a href="javascript:;" class="btn btn-icon btn-twitter share">
                  <ion-icon name="logo-twitter"></ion-icon>
              </a>
            </ShareNetwork>

        </div>

      </div>
    </div>

    <div class="card bg-block mb-2">
      <div class="section-title">{{ $t("Commission details") }}</div>
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="card product-card">
              <div class="card-body">
                <h2 class="title">{{ $t("Commissions Total") }}</h2>
                <div class="price">
                  {{ $t("$") }} {{ commissionsinfo.commissions_total }}
                </div>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-success btn-block"
                  @click="$router.push('/commissionsdetail?type=all')"
                  >{{ $t("Full details") }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card product-card">
              <div class="card-body">
                <h2 class="title">{{ $t("Commission Today") }}</h2>
                <div class="price">
                  {{ $t("$") }} {{ commissionsinfo.commission_today }}
                </div>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-success btn-block"
                  @click="$router.push('/commissionsdetail?type=today')"
                  >{{ $t("Today's details") }}
                  </a>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card product-card">
              <div class="card-body">
                <h2 class="title">{{ $t("Commission Transferable") }}</h2>
                <div class="price">
                  {{ $t("$") }} {{ commissionsinfo.commission_transferable }}
                </div>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-warning btn-block"
                  @click="showcommission"
                  >{{ $t("Transfer out") }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card bg-block mb-2">
      <div class="section-title">
        {{ $t("My Team") }} (ID:{{$store.state.investorinfo.name}})
        <span class="teamdetail" @click="$router.push('/teamdetail')"
          >{{ $t("Team Details") }} >
        </span>
      </div>
      <div class="card-body">
        <div v-html="teamdesc">
        </div>
        <div class="row rowchip">
          <div class="col-4">
            <div class="chip chip-info chip-media">
              <i class="chip-icon bg-primary level-chip-i">
                <span>level A</span>
              </i>
              <span class="chip-label">
                {{ teaminfo.next_one_count }}
              </span>
              <span class="commission_proportion">{{$store.state.investorinfo.business_configs.commissionA_share_proportion}}%</span>
            </div>
          </div>
            
          <div class="col-4">
            <div class="chip chip-primary chip-media">
              <i class="chip-icon bg-success level-chip-i">
                <span>level B</span>
              </i>
              <span class="chip-label">
                {{ teaminfo.next_two_count }}
              </span>
              <span class="commission_proportion">{{$store.state.investorinfo.business_configs.commissionB_share_proportion}}%</span>
            </div>
          </div>

          <div class="col-4">
            <div class="chip chip-media chip-danger">
              <i class="chip-icon bg-warning level-chip-i">
                <span>level C</span>
              </i>
              <span class="chip-label">
                {{ teaminfo.next_three_count }}
              </span>
              <span class="commission_proportion">{{$store.state.investorinfo.business_configs.commissionC_share_proportion}}%</span>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <p>{{ $t("Buying fund staff") }}</p>
            <p class="amount">{{ teaminfo.buying_fund_total_count }}</p>
          </div>
          <div class="col-6">
            <p>{{ $t("Member purchase amount") }}</p>
            <p class="amount">
              {{ $t("$") }}{{ teaminfo.next_purchase_total_amount }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section full mt-2">
        <div class="section-title">{{team_treatment.name}}</div>
        <div class="wide-block pt-2 pb-2" v-html="team_treatment.content">
            
        </div>
    </div>
  </div>
  <BottomMenu></BottomMenu>
  <!-- Dialog Form -->
  <div
    class="modal fade dialogbox"
    id="ctmodal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("Commission Transferable") }}</h5>
        </div>

        <div class="modal-body mb-2">
          <div>
            <!-- <h3 class="card-subtitle">{{ $t("Commission Transferable") }}:</h3> -->
            <h2
              class="
                card-title
                mb-1
              "
            >
              {{ $t("$") }}{{ commissionsinfo.commission_transferable }}
            </h2>
          </div>

          <div>
            <div class="text-left mt-3">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="amount">{{
                    $t("Transfer out")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="transfer_amount"
                    :placeholder="$t('Transfer amount')"
                  />
                  <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="btn-inline">
            <button
              type="button"
              class="btn btn-text-secondary"
              data-dismiss="modal"
            >
              {{ $t("CLOSE") }}
            </button>
            <button
              type="button"
              class="btn btn-text-primary"
              @click="handletransferoutcommission"
            >
              {{ $t("Transfer out") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- * Dialog Form -->
  <SuccessErr></SuccessErr>
  <Notice></Notice>
  <Chat></Chat>
</template>

<script>
import Clipboard from "clipboard";
import Header from "../components/Header.vue";
import Chat from "../components/Chat.vue";
import Notice from "@/components/Notice.vue";
import BottomMenu from "../components/BottomMenu.vue";
import SuccessErr from "../components/SuccessErr.vue";
import {
  getteaminfo,
  getcommissioninfo,
  transferoutcommission,
  getnewsCodedetail,
} from "../common/api";
import i18n from "../lang/index";
export default {
  name: "Team",
  components: {
    Header,
    BottomMenu,
    SuccessErr,
    Chat,
    Notice,
  },
  data() {
    return {
      teaminfo: {},
      commissionsinfo: {},
      transfer_amount: "",
      team_treatment: {},
      share_title: "Come And Make Money",
      share_text: "",
      teamdesc: "",
      transferaction: false,
    };
  },
  created() {
    this.$store.dispatch("asyncupdateinvestorinfo");
    this.$store.dispatch('asyncupdatesiteconfig');
    getnewsCodedetail("team_treatment").then((res)=>{
      if(res.code === 200) {
        this.team_treatment = res.data;
      }
    });
    getnewsCodedetail("teamdesc").then((res)=>{
      if(res.code === 200) {
        this.teamdesc = res.data.content;
      }
    })
  },
  mounted() {
    getteaminfo({}).then((res) => {
      if (res.code === 200) {
        this.teaminfo = res.data;
      }
    });
    this.handlegetcommissioninfo();
    this.share_text = String(this.$store.state.siteconfig.share_template).replace("SHARE_LINK", this.$store.state.investorinfo.referral_link);
  },
  methods: {
    share(to_app) {
      if(to_app=="whatsapp") {
        let share_url = "whatsapp://send?text="+ share_text +"&via=lopscoop";
        window.open(share_url);
      } else {
        let share_url = "#";
        window.open(share_url);
      }
    },
    showcommission() {
      $("#ctmodal").modal();
    },
    handletransferoutcommission() {
      let that = this;
      if(this.transferaction) {
        toastbox("error", 2000, i18n.global.t("Transfer too fast, please try again later"));
        return;
      }
      this.transferaction = true;
      if (
        Number(this.transfer_amount) > Number(this.commissionsinfo.commission_transferable) || Number(this.transfer_amount) < Number("0")
      ) {
        that.transferaction = false;
        toastbox("error", 2000, i18n.global.t("Insufficient commission"));
        return;
      }
      loading(i18n.global.t("In process"));
      transferoutcommission({
        amount: this.transfer_amount,
        action: this.inorout,
      }).then((res) => {
        loading_close();
        setTimeout(()=>{
          that.transferaction = false;
        }, 8000);
        if (res.code === 200) {
          $("#ct").modal("hide");
          toastbox("success", 2000, i18n.global.t("Transfer success"));
          this.transfer_amount = "";
          this.handlegetcommissioninfo();
          this.$store.dispatch("asyncupdateinvestorinfo");
          return;
        } else if (res.code === 403) {
          $("#ct").modal("hide");
          toastbox("error", 2000, res.errmsg);
          setTimeout(() => {
            this.$router.push("/login");
          }, 3000);
        } else {
          toastbox("error", 2000, res.errmsg);
          return;
        }
      });
    },
    handlegetcommissioninfo() {
      getcommissioninfo({}).then((res) => {
        if (res.code === 200) {
          this.commissionsinfo = res.data;
        }
      });
    },
    copy() {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        toastbox("success", 2000, i18n.global.t("Copy successfully"));
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        toastbox("error", 2000, i18n.global.t("Copy failed"));
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style scoped>
.teamdetail {
  color: #34C759;
}
.level-chip-i {
    width: 53px;
}
.rowchip {
    margin-left: -22px;
    margin-right: -8px;
}
.chip {
    background: #E1E1E1;
    font-size: 13px;
    color: #141515;
    line-height: 1em;
    height: 26px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    border-radius: 100px;
    width: 110px;
}
.chip .chip-label {
  padding: 0 12px;
  margin-left: 25px;
}
.share {
  width: 50px;
  margin-left: 20px;
}
.chip.chip-media {
    position: relative;
    padding-left: 18px;
}
.section-title {
  font-size: 15px;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #141515;
  font-weight: 500;
}
.amount {
  color: darkorange;
}
.commission_proportion {
  position: relative;
  right: 4%;
}
</style>