<template>
  <Header :title="$t('success')"></Header>
    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="error-page">
            <img src="/assets/img/success_limg.png" alt="alt" class="imaged square w200">
            <h1 class="title">{{$t('successful registration')}}</h1>
            <div class="text mb-5">
                {{$t('Thank you for your registration, you can log in to your account, or download the APP.')}}
            </div>
            <div class="fixed-footer">
                <div class="row">
                    <div class="col-6">
                        <a class="btn btn-primary btn-lg btn-block" @click="gologin">{{$t('Go Login')}}</a>
                    </div>
                    <div class="col-6">
                        <a class="btn btn-primary btn-lg btn-block" @click="get_app">{{$t('Download App')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * App Capsule -->
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
export default {
  name: "RegSuccess",
  components: {
    Header,
  },
  methods: {
    gologin() {
        this.$router.push({name: "login"});
    },
    get_app(){
        this.$router.push({ name: "app" });
    }
  }
};
</script>