<template>
    <Header :title="$t('Commission details')"></Header>

    <!-- App Capsule -->
    <div id="appCapsule">
        <ul class="listview image-listview detail-list text wrapper" v-infinite-scroll="loadMore"
            :v-infinite-scroll-disabled="loading" v-infinite-scroll-distance="10">
            <li v-for="wl in cm_logs" v-bind:key="wl.id">
                <a href="javascript:;" class="item">
                    <div class="in">
                        <span class="order">{{ wl.orderno }}</span>
                        <span class="status">{{ displaytype(wl.type) }}</span>
                        <h2 class="mt-1">{{ $t("$") }}{{ wl.amount }}</h2>
                        <span class="datetime">{{ wl.ctime }}</span>
                    </div>
                </a>
            </li>
            <li>
                <div class="spinner-border text-info mt-2" role="status" v-if="loading_show"></div>
                <div class="mt-2" v-if="no_more">
                    <p>{{ $t("no more data") }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import { getcommissionlog } from "../common/api";
import { useRoute } from "vue-router"
import i18n from "../lang/index";


export default {
    name: "WithdrawDetails",
    components: {
        Header,
    },
    data() {
        const route = useRoute();
        return {
            cm_logs: [],
            loading: false,
            loading_show: false,
            page: 1,
            max_page: 0,
            limit: 10,
            filter: route.query.type,
            no_more: false
        };
    },
    mounted() {
        this.loadMore();
        gotop();
    },
    methods: {
        set_max_page(count) {
            if(this.max_page !== 0) {
                return;
            }
            if (count % this.limit == 0) {
                this.max_page = parseInt(count / this.limit);
            } else {
                this.max_page = parseInt(count / this.limit) + 1;
            }
        },
        displaytype(type) {
            if(type === "buyfund") {
                return "investment";
            } else {
                return type;
            }
        },
        loadMore() {
            if(this.loading || this.no_more) {
                return;
            } else {
                this.loading = true;
                this.loading_show = true;
                this.getcommissionlog();
            }
        },
        getcommissionlog() {
            getcommissionlog({
                page: this.page,
                limit: this.limit,
                filter: this.filter,
            }).then((res) => {
                if (res.code === 200) {
                    this.set_max_page(res.data.count);
                    if (res.data.result.length < this.limit || this.page >= this.max_page) {
                        this.no_more = true;
                        this.loading_show = false;
                        this.loading = true;
                    } else {
                        console.log("this.loading", this.loading);
                        this.page = this.page + 1;
                        this.loading = false;
                        this.loading_show = false;
                    }
                    this.cm_logs.push(...res.data.result);
                }
            });
        }
    },
};
</script>

<style scoped>
.detail-list>li a.item:after {
    background-image: none;
}

.detail-list>li .item .in {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
}

.detail-list>li .item .in .order {
    color: #086;
}

.detail-list>li .item .in .status {
    position: relative;
    color: blue;
    left: 35px;
}

.detail-list>li .item .in .datetime {
    position: relative;
    left: 173px;
}

.addamount {
    color: green;
}

.eddamount {
    color: red;
}

.wrapper {
    height: calc(100vh - 55px);
    overflow: scroll;
}

.spinner-border {
    width: 1rem;
    height: 1rem;
}
</style>