<template>
  <Header :title="$t('Recharge')" :goroutername="goroutername"></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="error-page">
        <div class="icon-box bg-success" v-if="result === 'success'">
            <ion-icon name="checkmark-outline"></ion-icon>
        </div>
        <div class="icon-box bg-warning" v-if="result === 'processing'">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </div>
        <div class="icon-box bg-danger" v-if="result === 'fail'">
            <ion-icon name="close-outline"></ion-icon>
        </div>

        <span class="text mb-5">
            <span v-if="result === 'success'">{{$t("Successfully Recharge")}}</span>
            <span v-if="result === 'fail'">{{$t("Recharge failed")}}</span>
            <span v-if="result === 'processing'">{{$t("Recharging in progress")}}</span>
        </span>
        <h1 class="title">{{$t("$")}}{{amount}}</h1>
        <p class="text">
            {{$t("Order Number")}}
        </p>
        <p class="text mt-1 mb-5">
            {{orderno}}
        </p>
        <div class="fixed-footer">
            <div class="row">
                <div class="col-12">
                    <router-link to="/recharge" class="btn btn-primary btn-lg btn-block">{{$t("Recharge Again")}}</router-link>
                </div>
            </div>
        </div>
    </div>
  </div>
  <SuccessErr></SuccessErr>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import i18n from '../lang/index';
import { getpycresult } from "../common/api";
import SuccessErr from "../components/SuccessErr.vue";
export default {
  name: "RechargeResult",
  data() {
    return {
        result: "",
        amount: 0,
        orderno: "",
        queryinterval: "",
        goroutername: "recharge"
    };
  },
  components: {
    Header,
    SuccessErr,
  },
  mounted() {
    this.orderno = this.$router.currentRoute.value.params.orderno;
    this.query_recharge_result();
    this.query();
    if(this.$router.currentRoute.value.params.from === "rechargedetails") {
        this.goroutername = "back";
    }
  },
  methods: {
    query() {
        let that = this;
        this.queryinterval = setInterval(function(){
            that.query_recharge_result();
        }, 5000);
    },
    query_recharge_result() {
        getpycresult({
            recharge_orderno: this.orderno
        }).then((res)=>{
            if(res.code === 200) {
                this.result = res.data.status;
                this.amount = res.data.amount;
            } else {
                toastbox("error", 2000, res.errmsg);
            }
        });
    },
  },
  unmounted() {
    clearInterval(this.queryinterval);
  }
};
</script>

<style scoped>
.error-page .icon-box {
    min-width: 166px;
    max-width: 1166px;
    width: 166px;
    height: 166px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    font-size: 143px;
    border-radius: 402px;
    margin-left: 47px;
}
.error-page .title {
    font-size: 54px;
    margin-bottom: 20px;
    margin-top: 20px;
}
</style>