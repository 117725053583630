<template>
    <!-- top left -->
    <div class="fab-button bottom-right">
        <a href="javascript:;" class="fab bg-success" @click="gotowhatsapp">
            <ion-icon name="logo-whatsapp"></ion-icon>
        </a>
        <!-- <div class="dropdown-menu">
            <a class="dropdown-item bg-success" :href="$store.state.investorinfo.whatsapp" target="_blank">
                <ion-icon name="logo-whatsapp"></ion-icon>
                <p>Whatsapp</p>
            </a>

            <a class="dropdown-item" :href="$store.state.investorinfo.telegram" target= "_blank">
                <div class="icon-box">
                    <img class="dropdown-item" src="assets/img/home/telegram.webp"/>
                </div>
                <p>Telegram</p>
            </a>
        </div> -->
    </div>
    <!-- * top left -->
</template>

<script>
import { recordchatsource } from "../common/api";
export default {
  name: 'Chat',
  data() {
    return {
    }
  },
  created() {
    if(localStorage.getItem("imfpagetoken")) {
      this.$store.dispatch('asyncupdateinvestorinfo');
    }
    this.$store.dispatch('asyncupdatesiteconfig');
  },
  methods: {
    gotowhatsapp() {
        recordchatsource({"chat_source": "system whatsapp"}).then((res)=>{
            if(process.env.NODE_ENV === "app") {
                window.location.href = "xamla:" + this.$store.state.investorinfo.whatsapp;
            } else {
                window.location.href = this.$store.state.investorinfo.whatsapp;
            }
            return false;
        });
    }
  }
}
</script>

<style scoped>
.fab-button.bottom-right {
    position: fixed;
    bottom: 70px;
    right: 16px;
    z-index: 100;
    margin-bottom: env(safe-area-inset-bottom);
}
.fab-button .dropdown-menu .dropdown-item {
    padding: 0;
    background:none;
    width: 42px;
    height: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 14px 0;
    border-radius: 100%;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 3px 0 rgb(0 0 0 / 8%);
}
.fab-button .fab {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 20%);
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1E74FD;
    border-radius: 100%;
    color: #fff !important;
}
</style>
        
        
