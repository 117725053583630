<template>
  <Header :title="$t('Capital details')"></Header>
  <!-- App Capsule -->
  <div id="appCapsule">
    <ul class="listview image-listview detail-list text wrapper" v-infinite-scroll="loadMore"
      :v-infinite-scroll-disabled="loading" v-infinite-scroll-distance="10">
      <div class="input-wrapper">
        <select class="custom-select" v-model="type" @change="changeProduct">
          <option value="all">{{ $t("All") }}</option>
          <option value="in">{{ $t("Transfer in") }}</option>
          <option value="out">{{ $t("Transfer out") }}</option>
          <option value="interest">{{ $t("Interest") }}</option>
        </select>
      </div>
      <li v-for="cd in capital_details" v-bind:key="cd.id">
        <a href="javascript:;" class="item">
          <div class="in">
            <span class="order">{{ cd.orderno }}</span>
            <h2 class="mt-1">₹{{ cd.amount }}</h2>
            <span class="status">{{ cd.type }}</span>
            <span class="datetime">{{ cd.ctime }}</span>
          </div>
        </a>
      </li>
      <li>
        <div class="spinner-border text-info mt-2" role="status" v-if="loading_show"></div>
        <div class="mt-2" v-if="no_more">
          <p>{{ $t("no more data") }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import { getgetvalueaddlog } from "../common/api";
import i18n from "../lang/index";

export default {
  name: "ValueaddDetails",
  components: {
    Header,
  },
  data() {
    return {
      capital_details: [],
      loading: false,
      loading_show: true,
      page: 1,
      max_page: 0,
      limit: 10,
      type: "all",
      no_more: false
    };
  },
  mounted() {
    this.getassetsrecord();
    gotop();
  },
  methods: {
    changeProduct() {
      console.log(this.type)
      this.capital_details = [];
      this.page = 1;
      this.getassetsrecord();
    },
    set_max_page(count) {
        if(this.max_page !== 0) {
            return;
        }
        if (count % this.limit == 0) {
            this.max_page = parseInt(count / this.limit);
        } else {
            this.max_page = parseInt(count / this.limit) + 1;
        }
    },
    loadMore() {
      if(this.loading || this.no_more) {
          return;
      } else {
          this.loading = true;
          this.loading_show = true;
          this.getassetsrecord();
      }
    },
    getassetsrecord() {
      getgetvalueaddlog({
        page: this.page,
        limit: this.limit,
        type: this.type,
      }).then((res) => {
        if (res.code === 200) {
          this.set_max_page(res.data.count);
          if (res.data.result.length < this.limit || this.page >= this.max_page) {
            this.no_more = true;
            this.loading_show = false;
            this.loading = true;
          } else {
            console.log("this.loading", this.loading);
            this.page = this.page + 1;
            this.loading = false;
            this.loading_show = false;
          }
          this.capital_details.push(...res.data.result);
        }
      });
    }
  },
};
</script>

<style scoped>
.detail-list>li a.item:after {
  background-image: none;
}

.detail-list>li .item .in {
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.detail-list>li .item .in .order {
  color: #086;
}

.detail-list>li .item .in .status {
  color: blue;
  text-transform: capitalize;
}

.detail-list>li .item .in .datetime {
  position: absolute;
  right: 20px;
}

.addamount {
  color: green;
}

.eddamount {
  color: red;
}

.wrapper {
  height: calc(100vh - 55px);
  overflow: scroll;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
}
.custom-select {
    background-color: #4950;
}
</style>
