<template>
  <Header></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="login-form">
      <div class="section">
        <h1>{{ $t("Reset Password") }}</h1>
        <h4>{{ $t("Receive verification code to change password") }}</h4>
      </div>
      <div class="section mt-2 mb-5">
        <form>
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input type="text" disabled="true" name="areacode" v-model="area_code" style="padding: 0 0px 0 16px;" class="form-control area_code"/>
              <input
                type="text"
                class="form-control phone"
                name="phone"
                v-model="phone"
                :placeholder="$t('Phone')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed" v-if="verify_identidy">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="captcha"
                v-model="smscode"
                :placeholder="$t('Captcha')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
              <button
                id="send_btn"
                type="button"
                class="btn btn-primary sendsms_button"
                @click="handlesendsms"
              >
                {{ send_btn_text }}
              </button>
            </div>
          </div>

          <div class="form-group boxed" v-if="!verify_identidy">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                name="password"
                v-model="newpassword"
                :placeholder="$t('Password')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed" v-if="!verify_identidy">
            <div class="input-wrapper">
              <input
                type="password"
                class="form-control"
                name="password2"
                v-model="newpassword2"
                :placeholder="$t('Password (again)')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-button-group">
            <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!verify_identidy" @click="handlereset">
              {{ $t("Reset") }}
            </button>
            <button type="button" class="btn btn-primary btn-block btn-lg" v-else @click="handleverifyidentidy">
              {{ $t("Verify identidy") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- * App Capsule -->
  <SuccessErr></SuccessErr>
</template>

<script>
import Header from "../components/Header.vue";
import SuccessErr from "../components/SuccessErr.vue";
import i18n from "../lang/index";
import { phones, password } from "../common/rex";
import { sendsms, forgotchangepassword } from "../common/api";

export default {
  name: "ForgotpasswordView",
  data() {
    return {
      area_code: "91",
      phone: "",
      smscode: "",
      newpassword: "",
      newpassword2: "",
      send_btn_text: i18n.global.t("Send SMS"),
      verify_identidy: true,
      smsinterval: "",
      sendtoken: "",
    }
  },
  components: {
    Header,
    SuccessErr,
  },
  methods: {
    handlereset() {
      if(!password.test(this.newpassword)) {
        toastbox("error", 2000, i18n.global.t("Please enter a password of at least 6 characters, including at least 1 uppercase letter and 1 lowercase letter to ensure the security of your account"));
        return;
      }
      if (this.newpassword != this.newpassword2) {
        toastbox("error", 2000, i18n.global.t("The passwords entered twice do not match"));
        return;
      }
      this.handleforgotchangepassword({
        astion: "change",
        sendtoken: this.sendtoken,
        numbers: String(this.area_code) + String(this.phone),
        newpassword: this.newpassword,
        smscode: this.smscode
      });
    },
    handleverifyidentidy() {
      if(!phones['en-IN'].test(String(this.area_code) + String(this.phone))){ 
        toastbox("error", 2000, i18n.global.t("please enter a valid phone number"));
        return;
      }
      if(this.smscode.length < 4) {
        toastbox("error", 2000, i18n.global.t("please enter verification code"));
        return;
      }
      this.handleforgotchangepassword({
        astion: "verify_identidy",
        sendtoken: this.sendtoken,
        numbers: String(this.area_code) + String(this.phone),
        smscode: this.smscode
      });
    },
    handleforgotchangepassword(data) {
      let astion = data.astion
      loading(i18n.global.t("In process"));
      forgotchangepassword(data).then((res)=>{
        if(res.code === 200) {
          let that = this;
          if(astion === "change") {
            loading_close();
            toastbox("success", 2000, i18n.global.t("Password reset successfully, please log in again"));
            setTimeout(function(){
              that.$router.push("/login");
            }, 2000);
          } else {
            loading_close();
            toastbox("success", 2000, i18n.global.t("Authentication passed"));
            setTimeout(function(){
              that.verify_identidy = false;
            }, 2000);
          }
        } else {
          loading_close();
          toastbox("error", 2000, res.errmsg);
        }
      }).catch((e)=>{
        loading_close();
      })
    },
    handlesendsms() {
      clearInterval(this.smsinterval);
      if(!phones['en-IN'].test(this.phone)){ 
        toastbox("error", 2000, i18n.global.t("please enter a valid phone number"));
        return;
      }

      if(this.sendtoken === "" ) {
        this.sendtoken = this.guid();
      }
      $("#send_btn").attr("disabled", "disabled");
      loading(i18n.global.t("In process"));
      sendsms({
        sendtoken: this.sendtoken,
        numbers: String(this.area_code) + String(this.phone),
        type: "forgotpassword"
      }).then((res)=>{
        if(res.code===200) {
          $("#send_btn").removeClass("btn-primary").addClass("btn-secondary");
          this.send_btn_text = 60;
          loading_close();
          toastbox("success", 2000, i18n.global.t("Sent successfully"));
          var that = this;
          this.smsinterval = setInterval(function(){
            if(that.send_btn_text > 0) {
              that.send_btn_text = that.send_btn_text - 1;
            } else {
              $("#send_btn").removeClass("btn-secondary").addClass("btn-primary");
              $("#send_btn").attr("disabled", false);
              that.send_btn_text = i18n.global.t("Resend");
              clearInterval(that.smsinterval);
            }
          }, 1000);
        } else {
          loading_close();
          toastbox("error", 2000, res.errmsg);
          $("#send_btn").attr("disabled", false);
        }
      })
    },
    guid() {
      function S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
      }
      return (S4()+S4()+S4()+S4()+S4()+S4()+S4()+S4());
    },
  },
  unmounted() {
    clearInterval(this.smsinterval);
  }
};
</script>
<style scoped>
.sendsms_button {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 42px;
  width: 123px;
}
.area_code {
  width: 14%;
  position: relative;
  top: 42px;
}
.phone {
  width: 86%;
  position: relative;
  left: 12%;
  border-left: none;
}
</style>
