const InfiniteScrollDistance = {
  beforeMount(el, binding) {
    el.setAttribute('data-infiniteScrollDistance', binding.value);
  },
  updated(el, binding) {
    el.setAttribute('data-infiniteScrollDistance', binding.value);
  },
};

export default InfiniteScrollDistance;
