<template>
  <Header :showleft="false" :title="$store.state.siteconfig.website_name"></Header>
  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="login-form">
      <div class="section">
        <h1>{{ $t("Register") }}</h1>
        <h4>{{ $t("Fill the form to join us") }}</h4>
      </div>
      <div class="section mt-2 mb-5">
        <form>
          <!-- <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="name"
                v-model="name"
                :placeholder="$t('Full name')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div> -->

          <!-- <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="text"
                class="form-control"
                name="username"
                v-model="username"
                :placeholder="$t('Username')"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div> -->

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input type="text" disabled="true" name="areacode" v-model="area_code" style="padding: 0 0px 0 16px;"
                class="form-control area_code" />
              <input type="number" class="form-control phone" name="phone" v-model="phone" :placeholder="$t('Phone')" />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input type="text" class="form-control" name="captcha" v-model="smscode" :placeholder="$t('Captcha')" />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
              <button id="send_btn" type="button" class="btn btn-primary sendsms_button" @click="handlesendsms">{{
                  send_btn_text
              }}</button>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input type="password" class="form-control" name="password" v-model="password"
                :placeholder="$t('Password')" />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input type="password" class="form-control" name="password2" v-model="password2"
                :placeholder="$t('Password (again)')" />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input type="text" class="form-control" name="referral_code" v-model="referral_code"
                :placeholder="$t('Inviter code')" disabled id="registerbtn"/>
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <!-- <div class="text-left">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customChecka1"
              />
              <label
                class="custom-control-label text-muted"
                for="customChecka1"
              >
                I Agree
                <a href="javascript:;">Terms & Conditions</a>
              </label>
            </div>
          </div> -->

          <!-- <div class="form-links ml-1">
            <div>
              <a href="javascript:;" @click="get_app">{{ $t("To mobile app login") }}</a>
            </div>
          </div> -->

          <div class="form-button-group">
            <button type="button" class="btn btn-primary btn-block btn-lg" @click="handleRegister">
              {{ $t("Register") }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <SuccessErr></SuccessErr>
  </div>
  <!-- * App Capsule -->
</template>

<script>
import { register_sms, sendsms } from "../common/api";
import i18n from "../lang/index";
import Header from "../components/Header.vue";
import SuccessErr from "../components/SuccessErr.vue";
import { phones, username, password } from "../common/rex";

export default {
  name: "RegisterView",
  components: {
    Header,
    SuccessErr,
  },
  data() {
    return {
      area_code: "91",
      successmsg: "",
      errmsg: "",
      name: "",
      username: "",
      phone: "",
      smscode: "",
      password: "",
      password2: "",
      referral_code: "",
      sendtoken: "",
      smsinterval: "",
      countdown: 60,
      send_btn_text: i18n.global.t("Send SMS"),
    };
  },
  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
    if (this.$router.currentRoute.value.query.referral_code !== undefined) {
      this.referral_code = this.$router.currentRoute.value.query.referral_code;
    }
    if (this.$router.currentRoute.value.params.referral_code !== undefined) {
      this.referral_code = this.$router.currentRoute.value.params.referral_code;
    }
  },
  mounted() {
    let input = document.querySelector('#registerbtn');
    input.addEventListener('focus', () => {
      setTimeout(() => {
        input.scrollIntoView(false);
      }, 300);
    });
  },
  methods: {
    get_app() {
      window.location.href = this.$store.state.siteconfig.app_download_url;
      // this.$router.push("/app");
    },
    handlesendsms() {
      clearInterval(this.smsinterval);
      if (!phones['en-IN'].test(String(this.area_code) + String(this.phone))) {
        toastbox("error", 2000, i18n.global.t("please enter a valid phone number"));
        return;
      }
      if (this.sendtoken === "") {
        this.sendtoken = this.guid();
      }
      $("#send_btn").attr("disabled", "disabled");
      loading(i18n.global.t("In process"));
      sendsms({
        sendtoken: this.sendtoken,
        numbers: String(this.area_code) + String(this.phone),
        type: "register"
      }).then((res) => {
        if (res.code === 200) {
          $("#send_btn").removeClass("btn-primary").addClass("btn-secondary");
          this.send_btn_text = 60;
          loading_close();
          toastbox("success", 2000, i18n.global.t("Sent successfully"));
          var that = this;
          this.smsinterval = setInterval(function () {
            console.log("that.send_btn_text", that.send_btn_text);
            if (that.send_btn_text > 0) {
              that.send_btn_text = that.send_btn_text - 1;
            } else {
              $("#send_btn").removeClass("btn-secondary").addClass("btn-primary");
              $("#send_btn").attr("disabled", false);
              that.send_btn_text = i18n.global.t("Resend");
              clearInterval(that.smsinterval);
            }
          }, 1000);
        } else {
          loading_close();
          toastbox("error", 2000, res.errmsg);
          $("#send_btn").attr("disabled", false);
        }
      })
    },
    guid() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
    },
    valided_input() {
      // if (this.name.length < 1) {
      //   toastbox("error", 2000, i18n.global.t("Please type in your name"));
      //   return false;
      // }

      // if(!username.test(this.username)) {
      //   toastbox("error", 2000, i18n.global.t("Please fill in a valid username"));
      //   return false;
      // }

      if (!phones['en-IN'].test(String(this.area_code) + String(this.phone))) {
        toastbox("error", 2000, i18n.global.t("please enter a valid phone number"));
        return;
      }

      if (!password.test(this.password)) {
        toastbox("error", 2000, i18n.global.t("Please enter a password of at least 6 characters, including at least 1 uppercase letter and 1 lowercase letter to ensure the security of your account"));
        return false;
      }

      if (this.password != this.password2) {
        toastbox("error", 2000, i18n.global.t("The passwords entered twice do not match"));
        return false;
      }
      return true;
    },
    handleRegister() {
      let that = this;
      if (!this.valided_input()) {
        return;
      }
      if (this.smscode.length < 4) {
        toastbox("error", 2000, i18n.global.t("please enter verification code"));
        return;
      }

      loading(i18n.global.t("Registration in progress"));
      register_sms({
        // name: this.name,
        // username: this.username,
        phone: String(this.area_code) + String(this.phone),
        password: this.password,
        referral_code: this.referral_code,
        sendtoken: this.sendtoken,
        smscode: this.smscode
      }).then((res) => {
        if (res.code === 200) {
          loading_close();
          this.$router.push({ name: "RegSuccess" });
          // toastbox("success", 2000, i18n.global.t("The registration is successful, please download the app to log in"));
          // let that = this;
          // setTimeout(function () {
          //   this.$router.push({ name: "RegSuccess" });
          //   //window.location.href = that.$store.state.siteconfig.app_download_url;
          // }, 2000);
        } else {
          loading_close();
          toastbox("error", 2000, res.errmsg);
          return;
        }
      });
    },
  },
  unmounted() {
    clearInterval(this.smsinterval);
  }
};
</script>
<style scoped>
.sendsms_button {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 42px;
  width: 123px;
}

.area_code {
  width: 14%;
  position: relative;
  top: 42px;
}

.phone {
  width: 86%;
  position: relative;
  left: 12%;
  border-left: none;
}
</style>