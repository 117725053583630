<template>
  <!-- Modal Basic -->
  <div class="modal fade modalbox" id="investormodal" data-backdrop="static" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ curr_fund.name }}</h5>
          <a href="javascript:;" data-dismiss="modal">X</a>
        </div>
        <div class="modal-body">
          <div class="usertop ">
            <div class="usertopdiv">
              <div class="ft-txt">
                <div>{{ $t("Balance") }}</div>
                <div class="ft-28"> {{$t("$")}} {{  $store.state.investorinfo.balance }}</div>
              </div>
            </div>
          </div>
          <div class="van-cell-group van-hairline--top-bottom">
            <div class="van-cell">
              <div class="van-cell__title"><span>{{$t("Investment amount")}}</span>
              </div>
              <div class="van-cell__value"><span>{{ $t('$') }}{{ Number(curr_fund.initial_amount) }}</span>
              </div>
            </div>
            
            <div class="van-cell">
              <div class="van-cell__title"><span>{{ $t("Daily rate of return") }}</span>
              </div>
              <div class="van-cell__value"><span>{{ Number(curr_fund.interest_rate) }} % </span></div>
            </div>
            <div class="van-cell">
              <div class="van-cell__title"><span>{{ $t("Daily income") }}</span></div>
              <div class="van-cell__value"><span>{{ curr_fund.initial_amount * curr_fund.interest_rate / 100}}</span></div>
            </div>

            <div class="van-cell">
              <div class="van-cell__title"><span>{{ $t("Cycle") }}</span></div>
              <div class="van-cell__value"><span>{{ curr_fund.cycle }}</span></div>
            </div>

            <div class="van-cell">
              <div class="van-cell__title"><span>{{ $t("Frequency") }}({{ $t("Quantity available") }})</span></div>
              <div class="van-cell__value"><span>{{ max_frequency }}</span>
              </div>
            </div>

            <div class="van-cell">
              <div class="van-cell__title"><span>{{ $t("Total revenue") }}</span></div>
              <div class="van-cell__value"><span class="shouyitip"> {{ Number(curr_fund.initial_amount) }} + ({{ Number(curr_fund.initial_amount) }} x {{ Number(curr_fund.interest_rate) }}% x {{ curr_fund.cycle }}) = {{ (Number(curr_fund.initial_amount) + Number(curr_fund.initial_amount) * Number(curr_fund.interest_rate) * 0.01 * Number(curr_fund.cycle))}}</span></div>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-lg btn-block buy-b" @click="investment_project">{{ $t("Buy") }}</button>

          <!-- luckyearnings -->
          <div style="z-index: 1051;" v-if="showluckyearnings">
            <div class="luckyearnings-mask">
            </div>
            <div class="luckyearningsimg">
              <!-- <img src="/assets/luckyearnings/luckbg.png"/> -->
              <div class="luckyearningsimg-text">
                <p class="">{{ $t("Get lucky earnings") }}</p>
                <p style="font-size: 2rem; margin-top: 30px;">{{$t("$")}} {{lucky_earnings_amount}}</p>
              </div>
              <img class="luckyearnings-button" src="/assets/luckyearnings/botton.png" @click="gotocapitaldetails"/>
            </div>
          </div>
          <!-- * luckyearnings -->

        </div>
      </div>
    </div>
  </div>
  <!-- * Modal Basic -->
  <!-- Dialog Form -->
  <SuccessErr></SuccessErr>
</template>

<script>
import i18n from "../lang/index";
import SuccessErr from "./SuccessErr.vue";
import { investmentproject, investmentcheck } from "../common/api";
export default {
  name: "Header",
  components: {
    SuccessErr
  },
  props: {
    curr_fund: Object,
  },
  data() {
    return {
      lucky_earnings_amount: 0,
      showluckyearnings: false,
      investment_amount: 0,
      frequency: 1,
      max_frequency: 2
    };
  },
  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
  },
  // mounted(){
  //   $("#luckyearnings").modal();
  // },
  watch: {
    curr_fund(newValue) {
      this.showluckyearnings = false;
      this.handleinvestmentcheck(newValue);
    }
  },
  methods: {
    gotocapitaldetails() {
      this.$router.push({"name": "capitaldetails"});
    },
    handleinvestmentcheck(newValue) {
      investmentcheck({
        itemid: newValue.id,
      }).then((res) => {
        if (res.code === 200) {
          this.max_frequency = res.data.quantity_available;
          this.investment_amount = this.curr_fund.initial_amount * this.frequency;
        }
      });
    },
    frequency_less() {
      if (this.frequency > 1) {
        this.frequency = this.frequency - 1;
        this.investment_amount = this.frequency * this.curr_fund.initial_amount;
      }
    },
    frequency_add() {
      console.log("对比", this.frequency, this.max_frequency);
      if (this.frequency >= this.max_frequency) {
        toastbox("error", 2000, i18n.global.t("Exceeded maximum frequency"));
        return;
      } else {
        this.frequency = this.frequency + 1;
        this.investment_amount = this.frequency * this.curr_fund.initial_amount;
      }
    },
    closemodal() {
      $("#investormodal").modal("hide");
      this.investment_amount = "";
      this.frequency = 1;
    },
    investment_project() {
      if (Number(this.investment_amount) > Number(this.$store.state.investorinfo.balance)) {
        toastbox("error", 2000, i18n.global.t("Insufficient balance"));
        return;
      }
      if (
        Number(this.investment_amount) < Number(this.curr_fund.initial_amount)
      ) {
        toastbox("error", 2000, i18n.global.t(
          "The amount is less than the minimum investment amount"
        ));
        return;
      }
      let that = this;
      loading(i18n.global.t("In process"));
      investmentproject({
        amount: that.investment_amount,
        fund_id: that.curr_fund.id,
      }).then((res) => {
        loading_close();
        if (res.code === 200) {
          //$("#investormodal").modal("hide");
          if(Number(res.data.lucky_earnings_amount) > Number(0)) {
            toastbox("success", 600, i18n.global.t("Buy success"));
            setTimeout(()=>{
              that.lucky_earnings_amount = res.data.lucky_earnings_amount;
              that.showluckyearnings = true;
            }, 800);
          } else {
            toastbox("success", 2000, i18n.global.t("Buy success"));
          }
          that.$store.dispatch("asyncupdateinvestorinfo");
          that.$emit('refulshfundlist');
          that.handleinvestmentcheck(that.curr_fund);
          return;
        } else if (res.code === 403) {
          //$("#investormodal").modal("hide");
          toastbox("error", 2000, res.errmsg);
          setTimeout(() => {
            this.$router.push("/login");
          }, 3000);
        } else {
          toastbox("error", 2000, res.errmsg);
          return;
        }
      });
    },
  },
};
</script>

<style scoped>
.luckyearnings-mask {
  position: absolute;
  top: 0;
  width: 10000px;
  height: 10000px;
  background-color: rgb(34, 32, 32);
  opacity: 0.9;
  margin-left: -36px;
}
.luckyearningsimg {
  position: absolute;
  top:41%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background:url('../images/luckbg.png') no-repeat center center;
  background-size: cover;
  width: 400px;
  height: 370px;
}
.luckyearningsimg-text {
  color: #f56705;
  font-weight: 600;
  font-size: 1.3rem;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.luckyearnings-button {
  position: absolute;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 193px;
}
.dialogbox .modal-dialog .modal-content .modal-header .modal-title {
  color: #141515;
  text-align: left;
  margin: 0 auto;
  font-size: 15px;
}
.usertop {
    background-color: #40afff;
    background-image: linear-gradient(59deg,#40afff,#3574fa);
    color: #fff;
    border-radius: 1.1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1.5rem;
    margin-bottom: 0.4rem;
}

.usertop .usertopdiv {
    background: url(../images/invest_user.png) no-repeat 100% 100%;
    background-size: 30%;
}
.ft-txt {
  line-height: 2.5rem;
  text-align: left;
}
.ft-28 {
    font-size: 2rem;
}
.buy-b {
    position: fixed;
    bottom: 0px;
    left: 0px;
}

.van-cell--large {
    padding-top: 12px;
    padding-bottom: 12px;
}
.van-cell--large {
    padding-top: 12px;
    padding-bottom: 12px;
}
.van-cell {
  position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
    flex-direction: row;
    justify-content: space-between;
}
.van-field__left-icon {
    margin-right: 4px;
}
.van-field__value {
    overflow: visible;
}
.van-cell__title
{
  text-align: left;
}
.van-cell__value--alone {
    color: #323233;
    text-align: left;
}
.van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
}
.shouyitip {
  color: red;
  font-weight: 700;
}
.van-cell::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}

.van-field__control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    color: #323233;
    line-height: inherit;
    text-align: left;
    background-color: transparent;
    border: 0;
    resize: none;
}
</style>
