<template>
  <Header :title="$t('News')" :showleft="false"></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <ul class="listview image-listview">
      <li v-for="item in newlist" v-bind:key="item.id">
        <router-link :to="'/newsdetail/' + item.id" class="item">
          <div class="icon-box bg-primary">
            <ion-icon name="newspaper-outline"></ion-icon>
          </div>
          <div class="in">
            <div>
              <span class="news-title">{{item.name}}</span>
              <footer>{{item.ctime}}</footer>
            </div>
            <span class="text-muted">{{$t("Detail")}}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
  <BottomMenu></BottomMenu>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import BottomMenu from "../components/BottomMenu.vue";
import { getnewslist } from "../common/api";
export default {
  name: "News",
  data(){
    return {
      newlist: []
    }
  },
  components: {
    Header,
    BottomMenu,
  },
  mounted() {
    getnewslist({
      page: 1,
      limit: 20
    }).then((res)=>{
       if (res.code === 200) {
          this.newlist = res.data.result;
        }
    })
  },
  methods: {

  }
};
</script>

<style scoped>
.listview > li div {
    width: 85%;
    text-align: left;
}
.listview > li footer {
    color: #4F5050;
    margin-top: 5px;
    text-align: right;
}
.listview > li .news-title {
  font-size: 1em;
  max-height: 90px;
  overflow: hidden;
}
</style>