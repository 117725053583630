<template>
  <!-- loader -->
  <div id="loader">
      <div class="spinner-border text-primary" role="status"></div>
      <div>safety detection...</div>
  </div>
  <!-- * loader -->
  <router-view :key="key"/>
  <!-- Modal Loading -->
  <div class="modal-backdrop-loading fade show" id="loading" style="display: none;z-index: 2000;">
    <div class="loading-center">
        <div class="spinner-border text-info" role="status"></div>
        <p id="loadingmsg"></p>
    </div>
  </div>
  <!-- * Modal Loading -->
</template>

<script>
  export default {
    name: "App",
  };
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.modal-backdrop-loading.show {
    opacity: 1;
}
.modal-backdrop-loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    text-align: center;
}
.loading-center {
  position: relative;
  top: 40%;
}
.spinner-border {
    width: 3rem;
    height: 3rem;
}
#loader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #FFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
</style>
