import axios from "./http";
let {get, post, patch } = axios


// 登录
export const login = data => post("/fundapi/investor/login/", data)

// 注册
export const register = data => post("/fundapi/investor/register/", data)

// sms注册
export const register_sms = data => post("/fundapi/investor/register_sms/", data)

// sms发送
export const sendsms = data => post("/fundapi/investor/sendsms/", data)

//修改密码
export const changepassword = data => post("/fundapi/investor/changepassword/", data)

// 获取站点配置
export const getsiteconfig = data => get("/site/siteconfig/", data)

// 获取文章(通过id)
export function getnewsIddetail(id) {
    return get("/news/detail/" + id + "/");
}

// 获取文章(通过code)
export function getnewsCodedetail(code) {
    return get("/news/code/" + code + "/");
}

// 获取通知
export const getnotice = data => get("/news/notice/", data)

// 获取新闻列表
export const getnewslist = data => get("/news/list/", data)

// 获取个人信息
export const getinvestorinfo = data => get("/fundapi/investor/info/", data)

// 获取项目列表
export const getprojectlist = data => get("/fundapi/project/items/", data)

// 获取项目详情
export function getProjectdetail(id, data={}) {
    return get("/fundapi/project/item/" + id + "/", data);
}

//获取匿名提现记录
export const getwithdrawlog = data => get("fundapi/wallet/recentwithdrawlog/", data)

// 购买前检查
export const investmentcheck = data => post("fundapi/project/investmentcheck/", data)


// 项目下单
export const investmentproject = data => post("fundapi/project/investmentproject/", data)

// 资金记录
export const getassetsrecord = data => get("fundapi/wallet/assetsrecords/", data)

// 充值记录
export const getrechargelog = data => get("fundapi/wallet/recharge/", data)

// 充值
export const recharge = data => post("fundapi/wallet/recharge/", data)

// 获取充值通道
export const getpaychannel = data => get("fundapi/wallet/paychannel/", data)

// 获取充值跳转链接
export const gopayapi = data => post("fundapi/wallet/pyc/", data)

// 查询充值结果
export const getpycresult = data => post("fundapi/wallet/pycresult/", data)

// 提现
export const withdraw = data => post("fundapi/wallet/withdraw/", data)

// 获取提现信息
export const getwithdrawinfo = data => get("fundapi/wallet/withdrawinfo/", data)

// 获取提现银行卡
export const getbankinfo = data => get("fundapi/investor/bankinfo/", data)

// 编辑提现银行卡
export function editwithdraw(id, data) {
    return post("fundapi/investor/editbankinfo/" + id + "/", data);
}

// 增加提现银行卡
export const addwithdraw = data => post("fundapi/investor/bankinfo/", data)

// 提现记录
export const getwithdrawlogs = data => get("fundapi/wallet/withdraw/", data)

// 交易记录
export const getinvestmentlog = data => get("fundapi/project/investmentlog", data)

// 获取团队信息
export const getteaminfo = data => get("fundapi/investor/teaminfo/", data)

// 获取我的团队成员
export const getmyteam = data => get("fundapi/investor/myteam/", data)

// 获取佣金信息
export const getcommissioninfo = data => get("fundapi/investor/commissioninfo/", data)

// 获取佣金详情
export const getcommissionlog = data => get("fundapi/wallet/getcommissionlog/", data)

// 转出佣金
export const transferoutcommission = data => post('fundapi/wallet/transferoutcommission/', data)

// 增值宝转入转出
export const valueaddedtreasuretransfer = data => post("fundapi/wallet/valueaddedtreasuretransfer/", data)

// 忘记密码
export const forgotchangepassword = data => post("fundapi/investor/forgotchangepassword/", data)


// 获取签到日历
export const checkinget = data => get("fundapi/investor/checkin/", data)
//签到
export const checkin = data => post("fundapi/investor/checkin/", data)

//我的购买的订单
export const getmyitem = data => get("/fundapi/project/getmyitem/", data)

//我的订单反息记录
export function getiteminterestlog(itemid, data) {
    return get("/fundapi/project/getiteminterestlog/" + itemid + "/", data);
}

// 我的订单购买记录
export function getitembuyinfo(itemid, data) {
    return get("/fundapi/project/getitembuyinfo/" + itemid + "/", data);
}

// 增值宝详情
export const getgetvalueaddlog = data => get("/fundapi/wallet/getvalueaddlog/", data)

// 记录聊天来源
export const recordchatsource = data => post("fundapi/investor/recordchatsource/", data)
