<template>
  <Header :title="$t('Recharge Details')"></Header>

  <!-- App Capsule -->
  <div id="appCapsule">
    <ul
      class="listview image-listview detail-list text wrapper"
      v-infinite-scroll="loadMore"
      v-infinite-scroll-distance="10"
    >
      <li v-for="rl in recharge_logs" v-bind:key="rl.id">
        <router-link :to="'/rechargeresult/' + rl.orderno" class="item">
          <div class="in">
            <span class="order">{{ rl.orderno }}</span>
            <h2 class="mt-1">{{ $t("$") }}{{ rl.amount }}</h2>
            <span class="status">{{ rl.status }}</span>
            <span class="datetime">{{ rl.ctime }}</span>
          </div>
        </router-link>
      </li>
      <li>
        <div class="spinner-border text-info mt-2" role="status" v-if="loading_show"></div>
        <div class="mt-2" v-if="no_more"><p>{{$t("no more data")}}</p></div>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import { getrechargelog } from "../common/api";
import i18n from "../lang/index";

export default {
  name: "RechargeDetails",
  components: {
    Header,
  },
  data() {
    return {
      recharge_logs: [],
      loading: false,
      loading_show: false,
      page: 1,
      max_page: 0,
      limit: 10,
      no_more: false
    };
  },
  mounted() {
    this.getrechargelogs();
    gotop();
  },
  methods: {
    set_max_page(count) {
        if(this.max_page !== 0) {
            return;
        }
        if (count % this.limit == 0) {
            this.max_page = parseInt(count / this.limit);
        } else {
            this.max_page = parseInt(count / this.limit) + 1;
        }
    },
    loadMore() {
      if(this.loading || this.no_more) {
          return;
      } else {
          this.loading = true;
          this.loading_show = true;
          this.getrechargelogs();
      }
    },
    getrechargelogs() {
      // loading(i18n.global.t("Loading..."));
      getrechargelog({
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        if (res.code === 200) {
          this.set_max_page(res.data.count);
          if(res.data.result.length < this.limit || this.page >= this.max_page) {
            this.no_more = true;
            this.loading_show = false;
            this.loading = true;
          } else {
            console.log("this.loading", this.loading);
            this.page = this.page + 1;
            this.loading = false;
            this.loading_show = false;
            // loading_close();
          }
          this.recharge_logs.push(...res.data.result);
        }
      });
    }
  },
};
</script>

<style scoped>
.detail-list > li a.item:after {
  background-image: none;
}
.detail-list > li .item .in {
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.detail-list > li .item .in .order {
  color: #086;
}
.detail-list > li .item .in {
  text-transform: lowercase;
}
.detail-list > li .item .in .status {
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
  color: blue;
  left: 5px;
}
.detail-list > li .item .in .datetime {
    position: absolute;
    right: 20px;
}
.addamount {
  color: green;
}

.eddamount {
  color: red;
}
.wrapper {
  height: calc(100vh - 55px);
	overflow:scroll;
}
.spinner-border {
    width: 1rem;
    height: 1rem;
}
</style>