<template>
  <!-- Dialog Form -->
  <div
    class="modal fade dialogbox"
    id="vat_tmodal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t("value added treasure")}}</h5>
        </div>

        <div class="modal-body mb-2">
            <div>
              <!-- <h5 class="card-subtitle">{{ $t("Balance") }}:</h5> -->
              <h2 class="card-title mt-1">
                {{ $t("$") }}{{  $store.state.investorinfo.valueadded_treasure_balance }}
              </h2>
            </div>

            <div>
              <div class="text-left mt-3">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="amount">{{
                      button_span
                    }}</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="transfer_amount"
                      :placeholder="$t('Transfer amount')"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="modal-footer">
            <div class="btn-inline">
              <button
                type="button"
                class="btn btn-text-secondary"
                data-dismiss="modal"
              >
                {{$t("CLOSE")}}
              </button>
              <button
                type="button"
                class="btn btn-text-primary"
                @click="handlevalueaddedtreasuretransfer"
              >
                {{ button_span }}
              </button>
            </div>
          </div>
      </div>
    </div>
  </div>
  <SuccessErr></SuccessErr>
  <!-- * Dialog Form -->
</template>
<script>
import i18n from '../lang/index';
import SuccessErr from "./SuccessErr.vue";
import { valueaddedtreasuretransfer } from "../common/api";

export default {
  name: "ValueAddedTreaSure",
  props: {
    inorout: String,
  },
  components: {
    SuccessErr,
  },
  data() {
    return {
        transfer_amount: "",
        button_span: i18n.global.t('Transfer in'),
        transferaction: false,
    };
  },
  created() {
    this.$store.dispatch("asyncupdateinvestorinfo");
  },
  mounted() {
    
  },
  watch: {
    inorout: function(newval) {
      if(this.inorout == "in") {
        this.button_span = i18n.global.t('Transfer in');
      } else {
        this.button_span = i18n.global.t('Transfer out');
      }
    },
  },
  methods: {
    handlevalueaddedtreasuretransfer() {
      let that = this;
      if(this.transferaction) {
        toastbox("error", 2000, i18n.global.t("Transfer too fast, please try again later"));
        return;
      }
      this.transferaction = true;
      if( Number(this.transfer_amount) < Number("0")) {
        this.transferaction = false;
        toastbox("error", 2000, i18n.global.t("Insufficient balance"));
        return;
      }
      if (this.inorout === "out" && Number(this.transfer_amount) > Number(this.$store.state.investorinfo.valueadded_treasure_balance)) {
        this.transferaction = false;
        toastbox("error", 2000, i18n.global.t("Insufficient balance"));
        return;
      }

      if (this.inorout === "in" && Number(this.transfer_amount) > Number(this.$store.state.investorinfo.balance)) {
        this.transferaction = false;
        toastbox("error", 2000, i18n.global.t("Insufficient balance"));
        return;
      }
      loading(i18n.global.t("In process"));
      valueaddedtreasuretransfer({
        amount: this.transfer_amount,
        action: this.inorout
      }).then((res)=>{
        loading_close();
        setTimeout(()=>{
          that.transferaction = false;
        }, 8000);
        if (res.code === 200) {
          $("#vat_tmodal").modal("hide");
          toastbox("success", 2000, i18n.global.t("Transfer success"));
          this.transfer_amount = "";
          this.$store.dispatch('asyncupdateinvestorinfo');
          return;
        } else if (res.code === 403) {
          $("#vat_tmodal").modal("hide");
          toastbox("error", 2000, res.errmsg);
          setTimeout(() => {
            that.$router.push("/login");
          }, 3000);
        } else {
          toastbox("error", 2000, res.errmsg);
          return;
        }
      })
    }
  },
};
</script>

<style scoped>
</style>