import InfiniteScroll from './InfiniteScroll';
import InfiniteScrollDisabled from './InfiniteScrollDisabled';
import InfiniteScrollDistance from './InfiniteScrollDistance';


const directivesList = {
  InfiniteScroll,
  InfiniteScrollDisabled,
  InfiniteScrollDistance
};

const scroll = {
  install: function (app) {
    Object.keys(directivesList).forEach((key) => {
      app.directive(key, directivesList[key]); // 注册
    });
  }
};

export default scroll;// 抛出