<template>
     <!-- welcome notification  -->
    <div class="notification-box" v-if="shownotification">
      <div class="notification-dialog ios-style">
          <div class="notification-header">
              <div class="in">
                  <img src="/assets/img/icon/72x72.png" alt="image" class="imaged w24 rounded">
                  <strong>{{$t("Notice")}}</strong>
              </div>
              <div class="right">
                  <span>{{mtime}}</span>
                  <a href="javascript:;" class="close-button" @click="close_notice">
                      <ion-icon name="close-circle"></ion-icon>
                  </a>
              </div>
          </div>
          <div class="notification-content">
              <div class="in">
                  <!-- <h3 class="subtitle">Hello There</h3> -->
                  <div class="subtitle" v-html="content">
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- * welcome notification *  -->
</template>

<script>
import { getnotice } from "../common/api";
export default {
  name: 'Notice',
  data() {
    return {
      shownotification: false,
      content: "",
      mtime: "",
      noticeinterval: "",
    }
  },
  created() {
    let that = this;
    that.get_notice();
    this.noticeinterval = setInterval(function(){
      that.get_notice();
    }, 15000);
  },
  methods: {
    get_notice() {
      getnotice({"new_version": 1.4}).then((res)=>{
        if(res.code===200) {
          let key = "notice_" + res.data.mtime;
          if(res.data.code === "highnotice" && (this.$router.currentRoute.value.params.to === "home" || this.$router.currentRoute.value.params.to === "home1")) {
            this.mtime = res.data.mtime.substr(0,10);
            this.content = res.data.content;
            this.notification(3600000);
          } else if(!localStorage.getItem(key) && localStorage.getItem("imfpagetoken")) {
            this.mtime = res.data.mtime.substr(0,10);
            this.content = res.data.content;
            this.notification(3600000);
            localStorage.setItem(key, "1");
          }
        }
      });
    },
    notification(times) {
      let that = this;
      that.shownotification = true;
      setTimeout(()=>{
        that.shownotification = false;
      }, times+300)
    },
    close_notice() {
      this.shownotification = false;
    }
  },
  unmounted() {
    clearInterval(this.noticeinterval);
  },
}
</script>

<style scoped>
.notification-box {
    position: fixed;
    left: 0;
    top: 67px;
    right: 0;
    width: 100%;
    z-index: 9999;
    transition: 0.3s all;
    margin-top: env(safe-area-inset-top);
    display: block;
}
.notification-dialog.ios-style .notification-content {
    font-size: 13px;
    line-height: 1.4em;
    padding: 14px 16px 20px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #141515;
    background: #F9F9F9;
    height: 543px;
    overflow: scroll;
}
.notification-dialog.ios-style .notification-content .subtitle {
    color: #141515;
} 
.notification-dialog.ios-style .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #1E74FD;
    border-radius: 10px 10px 0 0;
}
</style>
