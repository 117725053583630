import { createI18n } from "vue-i18n";
import en from "./en-us";
import hi from "./hi";
import ru from "./ru";


const i18n = createI18n({
  locale: "en-us", // 定义默认语言为中文
  legacy: false,
  globalInjection: true,
  locale: localStorage.getItem('language') || 'en-us',
  messages: {
    'en-us': en,
    'hi': hi,
    'ru': ru,
  },
});

export default i18n;
